import * as turf from '@turf/turf';
import {BrushingExtension} from '@deck.gl/extensions';
let _map = null;

let pointSize = 3;

const GEOJSONLAYERID = "ld_geojson_base_layer";
const POLYGONLAYERID = "ld_polygon_hl_layer";

const colors = [
    [255,255,204],
    [255,255,102],
    [255,255, 0],
    [255,204,0],
    [255,153,0],
    [255,102,51],
    [255,102,102],
    [255,0,102],
    [255,51,51],
    [255,0,0]
];

function install(map, callback){
    const start = new Date().getTime();
    _map = map;

    // const bbox = [[70, 17], [140, 55]];
    // map.fitBounds(bbox);

    map.addDeckGeoJsonLayer({
        id: GEOJSONLAYERID,
        //data: "http://localhost:5000/api/geojson/996",
        pickable: true,
        stroked: true,
        filled: true,
        extruded: false,
        pointType: 'circle',
        iconSizeScale: 30,
        lineWidthUnits: "meters",
        // pointRadiusUnits: "pixels",
        lineWidthScale: 1,
        lineWidthMinPixels: 0,
        lineWidthMaxPixels: 5,
        getFillColor: [200, 50, 50, 100],
        getLineColor: [150, 150, 150],
        getPointRadius: 1,
        getLineWidth: 25,
        brushingEnabled: false,
        brushingRadius: 30000,
        extensions: [new BrushingExtension()]
    });

    map.addDeckSolidPolygonLayer({
        id:POLYGONLAYERID,
        pickable: false,
        getPolygon: d => d.coordinates,
        wireframe: true,
        getFillColor: [50,255, 150, 150],
        extruded: false
    })

    map.on("pickobject",_pickObject)
    map.on("hoverObject", _onHover);

    let url = BasicUrl + "/api/geojson/2424";
    fetch(url).then(response => response.json()).then(json => {
        // console.log(json)
        map.updateDeckLayer(GEOJSONLAYERID, { data: json});

        const end = new Date().getTime();
        if(callback && typeof(callback) == "function"){
            setTimeout(() => {
                callback({
                    total: json.length,
                    duration: end - start
                });
            }, 500)
        }
    })
}

let mouseX, mouseY;
let enabled = false;
let hoverd = null;
function _pickObject(event){
    console.log(event)
    //中键拾取
    // if(event.originalEvent.button != 1) return;
    // mouseX = event.point.x;
    // mouseY = event.point.y;
    // enabled = true;
}


function _onHover(event){
    const data = [];
    if(event.object){
        data.push(event.object.geometry);
    }
    hoverd = event.object;

    _map.updateDeckLayer(POLYGONLAYERID,{
        data:data
    })
}


function uninstall(){
    _map.off("pickobject",_pickObject)
    _map.off("hoverObject", _onHover);

    enabled = false;
    _map.removeDeckLayers([GEOJSONLAYERID,POLYGONLAYERID]);

    _map = null;
}

export { install,uninstall};
