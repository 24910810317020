<template>
  <div class="shp-btn map-control-background">
    <el-tooltip class="box-item" effect="dark" content="加载SHP图层" placement="left">
      <el-button @click="onClickRectangle"><el-icon><BookOpenGlobe20Filled /></el-icon></el-button>
    </el-tooltip>
    <input @change="onChangeFolder" type="file" ref="folderInput" webkitdirectory directory multiple style="display:none" />
  </div>
  <div id="shp_fea_pro_panel" v-show="showProperties">
    <table v-if="properties">
      <tr v-for="(value, key) in properties" :key="key+value">
        <td class="label">{{key}}:</td><td>{{value}}</td>
      </tr>
    </table>
  </div>
  <el-dialog
      v-model="dialogOpened"
      title="选择数据源"
      width="600px"
      align-center
  >
    <el-table :data="tableData" style="width: 100%" height="250"
              highlight-current-row
              @current-change="handleSelectedChange">
      <el-table-column prop="name" label="数据源" />
      <el-table-column prop="size" label="大小(KB)" width="160" />
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="onSubmit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { Document } from '@element-plus/icons-vue'
import { BookOpenGlobe20Filled } from '@vicons/fluent'
import { ElMessage } from 'element-plus'

import { showShpLayer, loadDbf } from '@/scripts/custom/ShpLayer';

const shpreader = new FileReader();
const dbfreader = new FileReader();
export default {
  name: "LoadFiles",
  components: { Document, BookOpenGlobe20Filled },
  data(){
    return {
      dialogOpened: false,
      tableData: [],
      selected: null,
      showProperties: false,
      properties: null
    }
  },
  methods:{
    onClickRectangle(){
      this.$refs.folderInput.click();
    },
    onChangeFolder(event){
      const files = this.$refs.folderInput.files;
      const array = [];
      const map = {};
      let filename;
      for(const f of files) {
        filename = f.name.toLowerCase();
        if(filename.endsWith('.shp') || filename.endsWith('.dbf')) {
          filename = filename.substr(0, filename.length - 4);
          if(map[filename]) map[filename].push(f);
          else map[filename] = [f];
        }
      }
      for(const key in map) {
        if(map[key].length > 1) {
          array.push(...map[key]);
        }
      }
      if(array.length == 0) {
        ElMessage({
          message: '没有发现可加载的数据源',
          type: 'error',
        });
      } else if (array.length > 2){ //有多个shp图层，提供选择
        const tableData = [];
        for(let i = 0; i < array.length; i += 2) {
          const name = array[i].name.substr(0, array[i].name.length - 4);
          const size = Math.round((array[i].size + array[i + 1].size) / 1024);
          tableData.push({
            name,
            size
          });
        }
        this.tableData = tableData;
        this.dialogOpened = true;
        this.selected = null;
      } else {
        let shp, dbf;
        for(const f of array) {
          const name = f.name.toLowerCase();
          if(name.endsWith(".shp")) shp = f;
          else if(name.endsWith(".dbf")) dbf = f;
        }
        this._loadFile(shp, dbf);
      }
    },
    handleSelectedChange(val) {
      this.selected = val ? val.name : null;
    },
    onSubmit(){ //确定选中
      if(!this.selected) {
        ElMessage({
          message: '需选择数据源',
          type: 'error',
        });
        return;
      }

      const files = this.$refs.folderInput.files;
      let shp, dbf;
      for(const f of files) {
        if(f.name.substr(0, f.name.length - 4) == this.selected) {
          const name = f.name.toLowerCase();
          if(name.endsWith(".shp")) shp = f;
          else if(name.endsWith(".dbf")) dbf = f;
        }
      }
      this._loadFile(shp, dbf);
      this.closeDialog();
    },
    closeDialog(){
      this.dialogOpened = false;
      this.$refs.folderInput.value = '';
    },
    onFeatureHover(evt, properties) {
      this.properties = properties;
      if(properties) this.showProperties = true;
      else this.showProperties = false;
      console.log(properties);
    },
    _loadFile(shp, dbf) {
      const that = this;
      let shpBuffer, dbfBuffer;
      shpreader.onload = function (f) {
        try {
          showShpLayer(shpreader.result, (evt, properties) => {
            that.onFeatureHover(evt, properties);
          });
        } catch (err) {
          ElMessage({
            message: err.message,
            type: 'error',
          })
        }
      }
      shpreader.onerror = function (err) {
        ElMessage({
          message: err.message,
          type: 'error',
        })
      }
      shpreader.readAsArrayBuffer(shp);

      dbfreader.onload = function (f) {
        try {
          loadDbf(dbfreader.result);
        } catch (err) {
          ElMessage({
            message: err.message,
            type: 'error',
          })
        }
      }
      dbfreader.onerror = function (err) {
        ElMessage({
          message: err.message,
          type: 'error',
        })
      }
      dbfreader.readAsArrayBuffer(dbf);
    }
  }
}
</script>

<style scoped>
.shp-btn{
  position: absolute;
  right:5px;
  top: 85px;
  width:auto;
  height:auto;
  padding:0px;
  margin:0px;
  border-radius:8px;
  z-index:2;
}

.shp-btn  > button{
  display:block;
  font-size:23px;
  padding:5px 5px;
  border-radius: inherit;
  margin:0px 0px;
}

#shp_fea_pro_panel {
  position: absolute;
  background: rgba(50, 50, 50, 0.8);
  width:350px;
  height: auto;
  top:0px;
  right:0px;
  bottom:0px;
  left: auto;
  z-index: 10;
  color: #efefef;
  overflow: auto;
}

#shp_fea_pro_panel > table {
  width:100%;
  height: auto;
  margin: 15px 0px;
}

#shp_fea_pro_panel > table td {
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  width: auto;
}

#shp_fea_pro_panel > table td.label {
  text-align: right;
  width: 120px;
  margin-right: 18px;
}

</style>
