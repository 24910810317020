<template>
  <div class="map-tool-bar map-control-background" style="box-shadow: var(--el-box-shadow-lighter);">
    <el-tooltip class="box-item" effect="dark" content="放大" placement="right">
      <el-button class="map-tool-button" @click="zoomIn"><el-icon><ZoomIn /></el-icon></el-button>
    </el-tooltip>

    <el-tooltip class="box-item" effect="dark" content="缩小" placement="right">
      <el-button class="map-tool-button" @click="zoomOut"><el-icon><ZoomOut /></el-icon></el-button>
    </el-tooltip>

    <el-tooltip class="box-item" effect="dark" content="距离" placement="right">
      <el-button class="map-tool-button" @click="measure(1)"><el-icon><Line32Filled /></el-icon></el-button>
    </el-tooltip>

    <el-tooltip class="box-item" effect="dark" content="面积" placement="right">
      <el-button class="map-tool-button" @click="measure(2)"><el-icon><ArrowAutofitWidth20Regular /></el-icon></el-button>
    </el-tooltip>

    <el-tooltip class="box-item" effect="dark" content="坐标定位" placement="right">
      <el-button disabled="true" class="map-tool-button"><el-icon><Coordinate /></el-icon></el-button>
    </el-tooltip>
  </div>
</template>

<script>
import { getMap } from '@/libs/map/MapHelper'

import {ZoomOut,ZoomIn,Coordinate} from '@element-plus/icons-vue'
import { Line32Filled,ArrowAutofitWidth20Regular } from '@vicons/fluent'

import { ElMessage } from 'element-plus'


export default {
  name: "Toolbar",
  components:{Line32Filled,ArrowAutofitWidth20Regular,ZoomOut,ZoomIn,Coordinate},
  methods:{
      zoomIn(){
        getMap().zoomIn();
      },
      zoomOut(){
        getMap().zoomOut();
      },
      measure(type){
        getMap().measure(type);
        ElMessage({
          showClose: true,
          message: '鼠标左键确定点位；右键可结束绘制',
          offset: 20
        })
      }
  }
}
</script>

<style scoped>
  .map-tool-bar{
    position: absolute;
    left:0px;
    top:200px;
    width:auto;
    height:auto;
    padding:0px;
    margin:0px;
    z-index:2;
  }

  .map-tool-bar > button.map-tool-button{
    display:block;
    font-size:20px;
    padding:5px 5px;
    border-radius:2px;
    margin:0px 0px;
  }
</style>
