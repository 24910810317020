import * as turf from '@turf/turf';
import {SHPLoader, DBFLoader} from '@loaders.gl/shapefile';
import { getMap} from '@/libs/map/MapHelper';
import { default as proj4 } from 'proj4';
import * as pp from 'proj4';

const CUSTOM_LAYER_ID = "shp_layer_id";
let layerIndex = 0;
let proArray = null;
async function showShpLayer(arraybuffer, onHover){
    const map = getMap();
    if(!map) return;
    const shp_content = await SHPLoader.parse(arraybuffer, {worker: true});
    // console.log(proj4.Proj.projections.get('EPSG:4326'), pp);
    // proj4.defs("EPSG:4504","+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs");
    // console.log(shp_content, 'shp', proj4('EPSG:4504', 'EPSG:4326', [477967.3102047293, 5364559.707752219]));
    // /*
    //     PROJCS["CGCS2000_GK_CM_87E",
    //     GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",
    //     DATUM["D_China_2000",SPHEROID["CGCS2000", 6378137.0,298.257222101]],
    //     PRIMEM["Greenwich",0.0],
    //     UNIT["Degree",0.0174532925199433]],
    //     PROJECTION["Gauss_Kruger"],
    //     PARAMETER["False_Easting",500000.0],
    //     PARAMETER["False_Northing",0.0],
    //     PARAMETER["Central_Meridian",87.0],
    //     PARAMETER["Scale_Factor",1.0],
    //     PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]
    // */
    // //proj4.defs("EPSG:4504","+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs");
    //
    // // GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",
    // // DATUM["D_China_2000",
    // // SPHEROID["CGCS2000",6378137.0,298.257222101]],
    // // PRIMEM["Greenwich",0.0],
    // // UNIT["Degree",0.0174532925199433]]
    //
    // return;
    const {geometries, header} = shp_content;
    const {bbox, type} = header;
    const {minX, maxX, minY, maxY} = bbox;
    map.fitBounds([[minX, maxY], [maxX, minY]],
        {padding: {top: 10, bottom:25, left: 15, right: 15}}
    );
    const length = geometries.length;
    for(let i = 0; i < length; i++) {
        geometries[i].index = i;
    }
    map.removeDeckLayer(CUSTOM_LAYER_ID);
    if(type == 5 || type == 15 || type == 25){//多边形
        map.addDeckPolygonLayer({
            id: CUSTOM_LAYER_ID,
            data: geometries,
            pickable: true,
            stroked: true,
            filled: true,
            getPolygon: (d) => {
                const {polygonIndices, positions} = d;
                const points = [];
                for(let i = 0; i < positions.value.length; i+= positions.size){
                    points.push(positions.value.subarray(i, i + positions.size));
                }
                const polygon = [];
                for(let i = 0; i < polygonIndices.value.length - 1; i++){
                    const ring = [];
                    let index = polygonIndices.value[i];
                    const end = polygonIndices.value[i+1];
                    for(;index < end; index++){
                        ring.push(points[index]);
                    }
                    polygon.push(ring);
                }
                return polygon;
            },
            getColor: [255, 0, 0],
            extruded: false,
            lineWidthUnits: "pixels",
            lineWidthScale: 1,
            lineWidthMinPixels: 1,
            getFillColor: [randomBand(), randomBand(), randomBand(), 100],
            getLineColor: [88, 88, 88],
            getLineWidth: 2,
            autoHighlight: true,
            highlightColor: [randomBand(), randomBand(), randomBand(), 100],
            onHover: (e) => {
                const object = e.object;
                if(object) {
                    const idx = object.index;
                    if(proArray && proArray.length > idx) {
                        if(onHover) onHover(e, proArray[idx])
                    } else {
                        if(onHover) onHover(e, null);
                    }
                } else {
                    if(onHover) onHover(null, null);
                }
            }
        });
    }
    else{
        throw new Error("暂仅支持面要素");
    }
}

async function loadDbf(arraybuffer) {
    proArray = await DBFLoader.parse(arraybuffer, {worker: true});
}

function randomBand() {
    return parseInt(Math.random() * 200) + 50;
}

export {  showShpLayer, loadDbf }
