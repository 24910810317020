<template>
  <header class="mapseb-header">
    <h3>MapSEB</h3>
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
  .mapseb-header{
    margin:0px;
    height:0px;
    width:100%;
    height:54px;
    border-bottom:1px solid #999;
    box-sizing: border-box;
    background-color: rgb(248,248,248);
    text-align:left;
    vertical-align:middle;
  }

  h3{
    position: absolute;
    color:rgb(33, 37, 41);
    left: 20px;
  }

</style>
