import * as turf from '@turf/turf';
import {BrushingExtension} from '@deck.gl/extensions';
let _map = null;

let pointSize = 3;

const GEOJSONLAYERID = "draw_geojson_base_layer";
const POLYGONLAYERID = "draw_polygon_hl_layer";
const EXTRUDLAYERID = "draw_polygon_extrud_layer";
const TEXTLAYERID = "draw_polygon_text_layer";

const colors = [
    [255,255,204],
    [255,255,102],
    [255,255, 0],
    [255,204,0],
    [255,153,0],
    [255,102,51],
    [255,102,102],
    [255,0,102],
    [255,51,51],
    [255,0,0]
];

function install(map, callback){
    const start = new Date().getTime();
    _map = map;

    const bbox = [[70, 17], [140, 55]];
    map.fitBounds(bbox);

    map.addDeckGeoJsonLayer({
        id: GEOJSONLAYERID,
        //data: "http://localhost:5000/api/geojson/996",
        pickable: true,
        stroked: true,
        filled: true,
        extruded: false,
        pointType: 'circle',
        iconSizeScale: 30,
        lineWidthUnits: "meters",
        // pointRadiusUnits: "pixels",
        lineWidthScale: 1,
        lineWidthMinPixels: 0,
        lineWidthMaxPixels: 5,
        getFillColor: [155, 255, 205, 66],
        getLineColor: [150, 150, 150],
        getPointRadius: 1,
        getLineWidth: 25,
        brushingEnabled: false,
        brushingRadius: 30000,
        extensions: [new BrushingExtension()]
    });

    map.addDeckSolidPolygonLayer({
        id:POLYGONLAYERID,
        pickable: false,
        getPolygon: d => d.coordinates,
        wireframe: true,
        getFillColor: [50,255, 150, 150],
        extruded: false
    })

    map.addDeckSolidPolygonLayer({
        id:EXTRUDLAYERID,
        pickable: false,
        getPolygon: d => d.geometry.coordinates,
        getElevation: d => d.height,
        elevationScale:100,
        wireframe: true,
        getFillColor: d => colors[Math.min(parseInt(d.height / 50),9)],
        extruded: true,
        transitions: {
            elevationScale: 3000
        }
    })

    map.addDeckTextLayer({
        id:TEXTLAYERID,
        pickable: false,
        getPosition: d => [...d.center, d.height * 100 + 1000],
        getText: d => d.height.toString(),
        getSize: 24,
        getAngle: 0,
        visible: false,
        sizeMinPixels: 10,
        sizeMaxPixels: 40,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center'
    })

    map.on("mousedown",_mouseDown)
    map.on("mousemove",_mouseMove)
    map.on("mouseup",_mouseUp)
    map.on("hoverObject", _onHover);

    let url = "http://39.108.215.149:8085/api/geojson/996";
    url = BasicUrl + "/api/geojson/996";
    fetch(url).then(response => response.json()).then(json => {
        // console.log(json)
        map.updateDeckLayer(GEOJSONLAYERID, { data: json});
        for(let fea of json){
            if(fea.height){
                extrudData.push(fea);
            }
        }

        _map.updateDeckLayersInBatch([
            {
                id:EXTRUDLAYERID,
                data:extrudData
            },
            {
                id:TEXTLAYERID,
                data:extrudData
            }
        ])

        const end = new Date().getTime();
        if(callback && typeof(callback) == "function"){
            setTimeout(() => {
                callback({
                    total: json.length + extrudData.length,
                    duration: end - start
                });
            }, 500)
        }
    })
}

let mouseX, mouseY;
let enabled = false;
let hoverd = null;
function _mouseDown(event){
    //中键拾取
    if(event.originalEvent.button != 1) return;
    mouseX = event.point.x;
    mouseY = event.point.y;
    enabled = true;
    requestAnimationFrame(_update);
}

function _mouseUp(event){
    if(event.originalEvent.button != 1) return;
    enabled = false;
}

function _mouseMove(event){
    mouseX = event.point.x;
    mouseY = event.point.y;
}

function _onHover(event){
    const data = [];
    if(event.object){
        data.push(event.object.geometry);
    }
    hoverd = event.object;

    _map.updateDeckLayer(POLYGONLAYERID,{
        data:data
    })
}

let extrudData = [];

function _update(){
    if(enabled){
        if(hoverd){
            if(hoverd.height) hoverd.height += 1;
            else{
                hoverd.height = 1;
                extrudData.push(hoverd);
                hoverd.center = turf.center(hoverd.geometry).geometry.coordinates;
            }
            extrudData = [].concat(extrudData);

            _map.updateDeckLayer(EXTRUDLAYERID,{
                data:extrudData
            })

            _map.updateDeckLayer(TEXTLAYERID,{
                data:extrudData
            })
        }
        requestAnimationFrame(_update);
    }
}

function changeTitleVisible(visible){
    _map.updateDeckLayer(TEXTLAYERID,{ visible: visible })
}

function uninstall(){
    _map.off("mousedown",_mouseDown)
    _map.off("mousemove",_mouseMove)
    _map.off("mouseup",_mouseUp)
    _map.off("hoverObject", _onHover);

    enabled = false;
    _map.removeDeckLayers([GEOJSONLAYERID,POLYGONLAYERID,EXTRUDLAYERID,TEXTLAYERID]);
    extrudData = [];

    _map = null;
}

export { install,changeTitleVisible,uninstall};
