<template>
  <div class="basic-layer-item" @click="onClick">
    <img :src="src" />
    <span>{{text}}</span>
  </div>
</template>

<script>
export default {
  name: "BasicLayerItem",
  props: {
    src: String,
    text: String
  },
  methods:{
    onClick(){
      this.$emit("clickBasicLayerItem", this.text);
    }
  }
}
</script>

<style scoped>
  .basic-layer-item{
    width:70px;
    height:50px;
    position: absolute;
    top:5px;
    left:5px;
    cursor: pointer;
    z-index:1;
  }

  .basic-layer-item > img{
    width:inherit;
    height:inherit;
    position:relative;
    top:0px;
    left:0px;
  }

  .basic-layer-item > span{
    position: absolute;
    top: 0px;
    right:0px;
    color:#fff;
    font-family: 微软雅黑;
    font-size:12px;
    background-color:rgba(0,153,255,0.6);
    padding: 1px 4px;
  }

  .basic-layer-item:hover > span{
    background-color:rgba(0,133,235,1);
  }
</style>
