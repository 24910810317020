import { Map } from "./index";

const tdt_test_url = "https://t0.tianditu.gov.cn";

let _map = null;

/**
 * 单例，只允许初始化一次
 */
function initMap(callback){
    if(_map) return;
    _map = new Map({
        container: "map", // container ID
        // style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [110.8494, 30.1282], // starting position [lng, lat]
        zoom: 3,
        minZoom:2,
        dragRotate: true,
        touchPitch: true,
        touchZoomRotate: true,
        pitchWithRotate: true,
        attributionControl: false,
        customAttribution: false,
        preserveDrawingBuffer: false,
        renderWorldCopies: true,
        basicLayerGroups: {
            "vector": [{
                    "type": "raster",
                    "autoscale": true,
                    "bounds": [-180, -85, 180, 85],
                    "cacheControl": "max-age=43200,s-maxage=604800",
                    "center": [0, 0, 3],
                    "maxzoom": 19,
                    "minzoom": 1,
                    "name": "Mapbox Satellite",
                    "private": false,
                    "scheme": "xyz",
                    "tiles": [
                        tdt_test_url + "/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=c661344414abb8f286739ec65086b3c0"
                    ],
                },
                {
                    "type": "raster",
                    "autoscale": true,
                    "bounds": [-180, -85, 180, 85],
                    "cacheControl": "max-age=43200,s-maxage=604800",
                    "center": [0, 0, 3],
                    "maxzoom": 19,
                    "minzoom": 1,
                    "name": "baseAnnotationVector",
                    "private": false,
                    "scheme": "xyz",
                    "tiles": [
                        tdt_test_url + "/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=c661344414abb8f286739ec65086b3c0"
                        // tdt_test_url + "/cta_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cta&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=c661344414abb8f286739ec65086b3c0"
                    ],
                }
            ],
            "image": [{
                type: "raster",
                "autoscale": true,
                "bounds": [-180, -85, 180, 85],
                "cacheControl": "max-age=43200,s-maxage=604800",
                "center": [0, 0, 3],
                "maxzoom": 19,
                "minzoom": 1,
                "name": "Mapbox Satellite",
                "private": false,
                "scheme": "xyz",
                "tiles": [
                    tdt_test_url + "/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=c661344414abb8f286739ec65086b3c0"
                ],
            },
                {
                    type: "raster",
                    "autoscale": true,
                    "bounds": [-180, -85, 180, 85],
                    "cacheControl": "max-age=43200,s-maxage=604800",
                    "center": [0, 0, 3],
                    "maxzoom": 19,
                    "minzoom": 1,
                    "name": "baseAnnotationVector",
                    "private": false,
                    "scheme": "xyz",
                    "tiles": [
                        tdt_test_url + "/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=c661344414abb8f286739ec65086b3c0"
                    ],
                }
            ],
            "ter": [{
                "type": "raster",
                "autoscale": true,
                "bounds": [-180, -85, 180, 85],
                "cacheControl": "max-age=43200,s-maxage=604800",
                "center": [0, 0, 3],
                "maxzoom": 19,
                "minzoom": 1,
                "name": "Mapbox Satellite",
                "private": false,
                "scheme": "xyz",
                "tiles": [
                    tdt_test_url + "/ter_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ter&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=c661344414abb8f286739ec65086b3c0"
                ],
            },
                {
                    "type": "raster",
                    "autoscale": true,
                    "bounds": [-180, -85, 180, 85],
                    "cacheControl": "max-age=43200,s-maxage=604800",
                    "center": [0, 0, 3],
                    "maxzoom": 19,
                    "minzoom": 1,
                    "name": "baseAnnotationVector",
                    "private": false,
                    "scheme": "xyz",
                    "tiles": [
                        tdt_test_url + "/cta_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cta&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=c661344414abb8f286739ec65086b3c0"
                    ],
                }
            ],
            "osm": [{
                "type": "raster",
                "autoscale": true,
                "bounds": [-180, -85, 180, 85],
                "cacheControl": "max-age=43200,s-maxage=604800",
                "center": [0, 0, 3],
                "maxzoom": 19,
                "minzoom": 1,
                "name": "Mapbox Satellite",
                "private": false,
                "scheme": "xyz",
                "tiles": [
                    "https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                ],
            }]
        },
        style: {}
    });

    _map.on("load",() => {
        if(callback && typeof(callback) == "function"){
            callback.call(_map, _map);
        }
    });

    return _map;
}

function getMap(){
    return _map;
}

function installModule(module, callback){
    module.install(_map, callback);
}

function uninstallModule(module){
    module.uninstall(_map);
}

export {initMap, getMap,installModule,uninstallModule};
