import { getMap} from '@/libs/map/MapHelper';
import GeoTIFF, { fromUrl, fromUrls, fromArrayBuffer, fromBlob } from 'geotiff';

const CUSTOM_LAYER_ID = "custom_layer_id_0";
let layerIndex = 0;


async function showTiffLayer(arraybuffer){
    const map = getMap();
    if(!map) return;
    const tiff = await fromArrayBuffer(arraybuffer);
    const image = await tiff.getImage(0);
    // if(image.geoKeys.GeographicTypeGeoKey != 4490) {
    //     throw new Error('目前仅支持CGCS2000坐标系')
    // }
    const bbox = image.getBoundingBox();
    // console.log(bbox)
    // const data = await image.readRasters();
    // const rgb = await image.readRGB();
    // console.log(res, 'res')
    // console.log(await tiff.getImageCount(), 'tiff.getImageCount')
    // // console.log(image, rgb, image.getBytesPerPixel(), image.getSamplesPerPixel())
    console.log(image, 'image')
    console.log(image.getBlockWidth(), 'getBlockWidth')
    console.log(image.getBlockHeight(), 'getBlockHeight')
    console.log(image.getBoundingBox(), 'getBoundingBox')
    console.log(image.getBytesPerPixel(), 'getBytesPerPixel')
    console.log(image.getSamplesPerPixel(), 'getSamplesPerPixel')
    console.log(image.getBitsPerSample(), 'getBitsPerSample')
    console.log(image.getSampleFormat(), 'getSampleFormat')
    console.log(image.getOrigin(), 'getOrigin')
    console.log(image.getHeight(), 'getHeight')
    console.log(image.getWidth(), 'getWidth')
    console.log(image.pixelIsArea(), 'pixelIsArea')
    console.log(image.getGDALMetadata(), 'getGDALMetadata')
    console.log(image.getGDALNoData(), 'getGDALNoData')
    console.log(image.getGeoKeys(), 'getGeoKeys')
    console.log(bbox, 'bbox')
    // console.log(image.getReaderForSample(), 'getReaderForSample')
    const rasters = await image.readRasters()
    const width = rasters.width
    const height = rasters.height
    const unitHorizontal = (bbox[2] - bbox[0]) / width;
    const unitVertical = (bbox[3] - bbox[1]) / height;

    const pieces = parseInt(Math.ceil(width * height / 10000000))
    const pieceHeight = parseInt(height / pieces)
    const coordsHeight = (bbox[3] - bbox[1]) / pieces
    const setImageData = image.getBytesPerPixel() == 4 ? setImageData41 : setImageData3
    let min = 999999, max = -999999;
    const raster = rasters[0]
    for(const value of raster) {
        if(value > max) max = value
        else if(value < min) min = value
    }
    const range = max - min

    const rolors = new Uint32Array(rasters[0].buffer)
    const bili = 255 / 4294967295
    for(let h = 0 ; h < pieces; h++) {
        let length = width * pieceHeight
        const data = new Uint8ClampedArray(width * pieceHeight * 4)
        for(let i = length * h, n = 0; i < length * (h + 1); i++, n += 4) {
            setImageData(data, i, n)
        }
        map.addBitmapLayer({
            id: CUSTOM_LAYER_ID + h,
            bounds: [bbox[0], bbox[3] - ((h + 1) * coordsHeight), bbox[2], bbox[3] - (h * coordsHeight)],
            image: {
                width: width,
                height: pieceHeight,
                data: data
            },
            pickable: true,
            onClick: ({coordinate}) => {
                const x = Math.round((coordinate[0] - bbox[0]) / unitHorizontal);
                const y = Math.round((bbox[3] - coordinate[1]) / unitVertical);
                console.log(x, y, raster[y * width + x]);
            }
        })
    }

    const [minX, minY, maxX, maxY] = bbox
    map.fitBounds([[minX, maxY], [maxX, minY]],
        {padding: {top: 10, bottom:25, left: 15, right: 15}}
    );

    function setImageData41(target, index, index2) {
        if(raster[index] == 0) {
            target[index2 + 3] = 0
            return
        }
        let c = parseInt(((raster[index] - min) / range) * 255)
        target[index2] = c
        target[index2 + 1] = c
        target[index2 + 2] = c
        target[index2 + 3] = 255
    }

    function setImageData4(target, index, index2) {
        target[index2] = rasters[0][index]
        target[index2 + 1] = rasters[1][index]
        target[index2 + 2] = rasters[2][index]
        target[index2 + 3] = rasters[3][index]
    }

    function setImageData3(target, index, index2) {
        const r = rasters[0][index]
        const g = rasters[1][index]
        const b = rasters[2][index]
        if (r === g && r === b) return
        target[index2] = r
        target[index2 + 1] = r
        target[index2 + 2] = r
        target[index2 + 3] = 255
    }
}

export {  showTiffLayer }
