import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { Layer, project32, CompositeLayer, picking, COORDINATE_SYSTEM } from '@deck.gl/core';
import JSolidPolygonLayer from "@/libs/map/plus/layers/JSolidPolygonLayer";

const defaultProps = {
    ...JSolidPolygonLayer.defaultProps,
    onSubLayerInitialized: {
        type: 'function',
        value: null,
        compare: false,
        optional: true
    },
    baseURL: {
        type: 'object',
        value: "http://localhost:5000"
    }
}

export default class CompositePolygonLayer extends CompositeLayer{
    initializeState() {
        this.setState({
            subData: []
        });
    }

    updateState({props,changeFlags}) {
        if (!changeFlags.dataChanged) {
            return;
        }
        const { data,onSubLayerInitialized } = props;
        let pages = data.pages;
        if(pages){
            const startTime = new Date().getTime();
            this.setState({loadedCount: 0, loadedLength: 0});
            const promises = [];
            for(let page of pages){
                promises.push(this._loadSubLayerData(page));
            }

            Promise.any(promises).then(() => {
                this.setNeedsUpdate();
            })

            Promise.allSettled(promises).then(() => {
                this.setNeedsUpdate();
                const { endTime, loadedLength } = this.state;
                const speed = loadedLength / 1024 / 1024 / (endTime - startTime) * 1000;
                if(onSubLayerInitialized) onSubLayerInitialized({
                    ...data,
                    speed: Math.round(speed * 100) / 100
                });
            })
        }
    }

    async _loadSubLayerData(page){
        const promise1 = this._loadGeometries(page);
        const promise2 = this._loadProperties(page);
        const geometries = await promise1;
        const properties = await promise2;

        geometries.properties = properties;

        this.state.subData.push(geometries);

    }

    _loadProperties(page){
        return fetch(this.props.baseURL + "/api/sep/prop/" + page)
            .then((response) => response.text());
    }

    _loadGeometries(page){
        const _this = this;
        const promise = new Promise((resolve, reject) => {
            const worker = new Worker("./workers/test.js");
            worker.onmessage = ({data}) => {
                if(data.type == "length"){
                    _this._statisticsNetwork(data.length);
                }
                else{
                    if(resolve) resolve(data);
                    worker.terminate();
                }

            }
            worker.postMessage(this.props.baseURL + "/api/sep/geom/" + page);
        });
        return promise;
    }

    /**
     * 网络统计
     * @private
     */
    _statisticsNetwork(length){
        const { data } = this.props;
        const pages = data.pages.length;
        let {loadedCount, loadedLength} = this.state;
        loadedCount ++;
        loadedLength += length;
        this.setState({loadedCount, loadedLength});
        if(loadedCount == pages){
            this.setState({
                endTime: new Date().getTime()
            })
        }
    }

    renderLayers() {
        let layers = [];
        const { subData } = this.state;
        let index = 1;
        for(let data of subData){
            layers.push(new JSolidPolygonLayer(this.props,this.getSubLayerProps({
                id: this.id + "_" + index++,
                data: data
            })));
        }
        return layers;
    }
}

_defineProperty(CompositePolygonLayer, "defaultProps", defaultProps);

_defineProperty(CompositePolygonLayer, "layerName", 'CompositePolygonLayer');
