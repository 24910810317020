import { Map,ScaleControl } from 'mapbox-gl';
import { MapboxOverlay } from '@deck.gl/mapbox';
import MousePositionControl from './controls/MousePositionControl'
import DeckLayerManager from './DeckLayerManager';
import DrawManager from './handles/draw';
import MeasurerManager from './handles/measure';


export default class PlusMap extends Map {
    constructor(cfg) {
        const defaultCfg = {
            style: {
                version: 8,
                sources: {},
                layers: [],
                // "sprite": "./assets/",
                // // "sprite": "mapbox://sprites/mapbox/streets-v8",
                // "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
                // // "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf"
            }
        };


        if (cfg.style) {
            if (cfg.style.sources) {
                cfg.style.sources = Object.assign(defaultCfg.style.sources, cfg.style.sources);
            }

            if (cfg.style.layers) {
                cfg.style.layers = defaultCfg.style.layers.concat(cfg.style.layers);
            }

            cfg.style = Object.assign(defaultCfg.style, cfg.style);
        }

        cfg = Object.assign(defaultCfg, cfg);

        super(cfg);

        this._origin = {
            zoom: cfg.zoom || 5,
            center: cfg.center || [110.8494, 30.1282]
        };

        this._basicLayerGroups = cfg.basicLayerGroups;

        while (this._controls.length > 0) { //移除多余的控件
            this.removeControl(this._controls[0]);
        }

        this.on("style.load", () => {

            if (this._basicLayerGroups) {
                for (let groupId in this._basicLayerGroups) {

                    this.changeBasicLayerGroup(groupId);
                    break;
                }
            }

            const container = this.getCanvas().parentElement;

            const deckgl = new MapboxOverlay({
                interleaved: false,
                layers: [],
                onClick: (info, event) => {
                    if(info.picked){
                        this.fire("pickobject", {layer: info.layer, object: info.object});
                    }
                },
                onHover:(info, event) => {
                    if(info.picked && !container.style.cursor){
                        // container.style.cursor = "pointer";
                        container.style.cursor = "default";
                    }
                    else if(!info.picked){
                        container.style.cursor = "";
                    }

                    this.fire("hoverObject", info);
                }
            });
            this.addControl(deckgl);
            this._deckgl = deckgl;
            deckgl._deck.canvas.style.left = "0";
            deckgl._deck.canvas.style.top = "0";
            this._dlm = new DeckLayerManager(this.deck);

            this._drawManager = new DrawManager(this);
            this._measureManager = new MeasurerManager(this);

            // setTimeout(() => {
            //     console.log(this.deck.pickObject(901, 427, 0, ["test-test-layer"]))
            // },10000)
        });

        this.addControl(new MousePositionControl(),'bottom-left');
        const scale = new ScaleControl({
            maxWidth: 160,
            unit: 'imperial'
        });
        this.addControl(scale);

        scale.setUnit('metric');
    }

    get deck() {
        return this._deckgl._deck;
    }

    getLayer(layerId){
        const layers = this._dlm.layers;
        for(let lyr of layers){
            if(lyr.id == layerId) return lyr;
        }
        return null;
    }

    /**
     * 切换底图图层组
     * @param {图层组ID} groupId
     */
    changeBasicLayerGroup(groupId) {
        if (this._currentBasiceGroup == groupId) return;
        if (!this._basicLayerGroups[groupId]) return;

        if (this._currentBasiceGroup) {
            const ssources = this._basicLayerGroups[this._currentBasiceGroup];
            for (let i = 0; i < ssources.length; i++) {
                this.removeLayer(`${this._currentBasiceGroup}_basiclayer_${i}`);
                this.removeSource(`${this._currentBasiceGroup}_basicsource_${i}`)
            }
        }


        this._currentBasiceGroup = groupId;

        const bsources = this._basicLayerGroups[groupId];

        for (let i = 0; i < bsources.length; i++) {
            this.addSource(`${groupId}_basicsource_${i}`, bsources[i]);
        }

        let layers = this.style._layers;
        let beforeId = layers.length > 0 ? layers[0].id : null;

        for (let i = 0; i < bsources.length; i++) {
            this.addLayer({
                id: `${groupId}_basiclayer_${i}`,
                type: "raster",
                source: `${groupId}_basicsource_${i}`
            }, beforeId);
        }
    }

    /**
     * 添加新的deck图层
     * @param {图层对象} layer
     * @param beforeId
     */
    addDeckLayer(layer, beforeId){
        this._dlm.addLayer(layer, beforeId);
    }

    /**
     * 添加一个接受geojson对象为数据源的图层
     * @param {https://deck.gl/docs/api-reference/layers/geojson-layer} options
     * @param {非必填} beforeId
     */
    addDeckGeoJsonLayer(options, beforeId) {
        this._dlm.addGeoJsonLayer(options, beforeId);
    }

    /**
     * 添加一个文字的图层
     * @param {https://deck.gl/docs/api-reference/layers/text-layer} options
     * @param {非必填} beforeId
     */
    addDeckTextLayer(options, beforeId) {
        this._dlm.addTextLayer(options, beforeId);
    }

    /**
     * 添加一个散点的图层
     * @param {https://deck.gl/docs/api-reference/layers/scatterplot-layer} options
     * @param {非必填} beforeId
     */
    addDeckScatterplotLayer(options, beforeId) {
        this._dlm.addScatterplotLayer(options, beforeId);
    }

    /**
     * 添加热力图曾
     * @param {https://deck.gl/docs/api-reference/aggregation-layers/heatmap-layer} options
     * @param {非必填} beforeId
     */
    addDeckHeatmapLayer(options, beforeId) {
        this._dlm.addHeatmapLayer(options, beforeId);
    }

    /**
     * 添加一个多边形的图层
     * @param {https://deck.gl/docs/api-reference/layers/solid-polygon-layer} options
     * @param {非必填} beforeId
     */
    addDeckSolidPolygonLayer(options, beforeId){
        this._dlm.addSolidPolygonLayer(options, beforeId);
    }

    /**
     * 添加一个多边形的图层
     * @param {https://deck.gl/docs/api-reference/layers/polygon-layer} options
     * @param {非必填} beforeId
     */
    addDeckPolygonLayer(options, beforeId){
        this._dlm.addPolygonLayer(options, beforeId);
    }

    addDeckIconLayer(options, beforeId) {
        this._dlm.addIconLayer(options, beforeId);
    }

    addBitmapLayer(options, beforeId) {
        this._dlm.addBitmapLayer(options, beforeId);
    }

    /**
     * 移除一个DECK图层
     * @param {图层ID} layerId
     */
    removeDeckLayer(layerId) {
        this._dlm.removeLayer(layerId);
    }

    /**
     * 批量删除图层
     * @param  {图层ID数组}  layerIds
     */
    removeDeckLayers(layerIds){
        this._dlm.removeLayers(layerIds);
    }

    /**
     * 更新图层
     * @param {图层ID} layerId
     * @param {需要更新的配置项} options
     */
    updateDeckLayer(layerId, options) {
        this._dlm.updateLayer(layerId, options);
    }

    /**
     * 批量更新多个图层
     * @param options
     */
    updateDeckLayersInBatch(options){
        this._dlm.updateLayers(options);
    }

    /**
     * 判断图层ID是否已添加
     * @param {图层ID} layerId
     * @returns
     */
    isDeckLayerExist(layerId) {
        return this._dlm._isLayerIdExisted(layerId);
    }


    get deckLayers() {
        return this._dlm.layers;
    }


    /**
     * 地图复位
     * 使视图回到初始化时的层级和位置
     */
    reset() {
        this.easeTo({
            center: this._origin.center,
            zoom: this._origin.zoom,
            curve: 1,
            duration: 1000
        });
    }


    /**
     * 激活绘制控件
     * @param {1:多边形绘制, 2: 拉框绘制矩形} type
     */
    activateDraw(type){
        this._drawManager.activate(type);
    }

    /**
     * 反激活绘制组件
     * 清除一切绘制图层
     */
    deactivateDraw(){
        this._drawManager.deactivate();
    }

    cleanDraw(){
        this._drawManager.clean();
    }

    /**
     * 测量
     * @param {1:长度, 2: 面积} type
     */
    measure(type){
        this._measureManager.activate(type);
    }

    deactivateMeasure(){
        this._measureManager.deactivate();
    }

    /**
     * 清除量算
     */
    cleanMeasure(){
        this._measureManager.clean();
    }

    /**
     * 销毁方法
     */
    dispose(){
        this.deactivateMeasure();
        this.deactivateDraw();
        this._dlm.removeAllLayers();
        while(this._controls.length > 0){
            this.removeControl(this._controls[0]);
        }
        const style = this.getStyle();
        for(let layer of style.layers){
            this.removeLayer(layer.id);
        }
        for(let source in style.sources){
            this.removeSource(source);
        }
        this.getContainer().innerHTML = "";
    }
}
