import {GeoJsonLayer, ScatterplotLayer,
        TextLayer, IconLayer,
        SolidPolygonLayer,PolygonLayer,
        BitmapLayer} from '@deck.gl/layers';
import {HeatmapLayer} from '@deck.gl/aggregation-layers';

import { charSet } from './constants/CharacterSet';

export default class DeckLayerManager{
    constructor(deckgl){
        this.deckgl = deckgl;

        this._cache = {};
    }

    /**
     * 增加图层
     * @param Layer 图层类
     * @param options
     * @param beforeId
     * @private
     */
    _addLayer(Layer, options, beforeId){
        if(! options.id) throw new Error("缺失图层‘id’参数");
        // if(this._isLayerIdExisted(options.id)) throw new Error(`“${options.id}”图层已存在`);

        // if(this._isLayerIdExisted(options.id)){
        //     Object.assign(this._cache[options.id], options);
        // }
        // else{
        //     this._cache[options.id] = options;
        // }


        const layer = new Layer(options);

        // if(this._cache[options.id].data) delete this._cache[options.id].data;

        const layers = this.layers;
        let array = [];
        if(beforeId){
            for(let i = 0 ; i < layers.length; i++){
                // if(layers[i].id == options.id) continue;
                if(layers[i].id == beforeId){
                    array.push(layer);
                }
                array.push(layers[i]);
            }
        }
        else{
            for(let i = 0 ; i < layers.length; i++){
                // if(layers[i].id == options.id) continue;
                array.push(layers[i]);
            }
            array.push(layer);
        }
        this.deckgl.setProps({layers: array});
    }

    /**
     * 添加图层
     * @param layer 图层对象
     * @param beforeId
     */
    addLayer(layer, beforeId){
        const layers = this.layers;
        // const props = layer.props;

        // if(this._isLayerIdExisted(props.id)){
        //     Object.assign(this._cache[props.id], props);
        // }
        // else{
        //     this._cache[props.id] = Object.assign({}, props);
        // }
        // console.log(this._cache[props.id].data)
        // if(this._cache[options.id].data) delete this._cache[options.id].data;

        let array = [];
        if(beforeId){
            for(let i = 0 ; i < layers.length; i++){
                // if(layers[i].id == props.id) continue;
                if(layers[i].id == beforeId){
                    array.push(layer);
                }
                array.push(layers[i]);
            }
        }
        else{
            for(let i = 0 ; i < layers.length; i++){
                // if(layers[i].id == props.id) continue;
                array.push(layers[i]);
            }
            array.push(layer);
        }
        this.deckgl.setProps({layers: array});
    }

    /**
     * 添加热力图层
     * @param {https://deck.gl/docs/api-reference/aggregation-layers/heatmap-layer} options
     * @param {非必填} beforeId
     */
    addHeatmapLayer(options, beforeId) {
        this._addLayer(HeatmapLayer, options, beforeId);
    }

    /**
     * 添加一个接受geojson对象为数据源的图层
     * @param {https://deck.gl/docs/api-reference/layers/geojson-layer} options
     * @param {非必填} beforeId
     */
    addGeoJsonLayer(options, beforeId){
        this._addLayer(GeoJsonLayer, options, beforeId);
    }

    /**
     * 添加一个文字的图层
     * @param {https://deck.gl/docs/api-reference/layers/text-layer} options
     * @param {非必填} beforeId
     */
    addTextLayer(options, beforeId){
        if(!options.characterSet) options.characterSet = charSet;
        this._addLayer(TextLayer, options, beforeId);
    }

    /**
     * 添加一个散点的图层
     * @param {https://deck.gl/docs/api-reference/layers/scatterplot-layer} options
     * @param {非必填} beforeId
     */
    addScatterplotLayer(options, beforeId){
        this._addLayer(ScatterplotLayer, options, beforeId);
    }

    /**
     * 添加一个多边形的图层
     * @param {https://deck.gl/docs/api-reference/layers/solid-polygon-layer} options
     * @param {非必填} beforeId
     */
    addSolidPolygonLayer(options, beforeId){
        this._addLayer(SolidPolygonLayer, options, beforeId);
    }

    addPolygonLayer(options, beforeId){
        this._addLayer(PolygonLayer, options, beforeId);
    }

    addIconLayer(options, beforeId){
        this._addLayer(IconLayer, options, beforeId);
    }

    addBitmapLayer(options, beforeId) {
        this._addLayer(BitmapLayer, options, beforeId);
    }

    get layers(){
        return this.deckgl.props.layers;
    }

    /**
     * 更新图层
     * @param {图层ID} layerId
     * @param {需要更新的配置项} options
     */
    updateLayer(layerId, options){
        let layer = this.layers.find(l => l.id === layerId);
        if(!layer) return;
        const constructor = layer.constructor;
        setProps(options, layer.props);
        layer = new constructor(options);

        const layers = [];
        for(let lyr of this.layers){
            if(lyr.id == layerId){
                layers.push(layer);
            }
            else{
                layers.push(lyr);
            }
        }
        this.deckgl.setProps({layers});
    }

    /**
     * 同期更新多个图层
     * @param options
     */
    updateLayers(options){
        for(let opt of options) {
            if(!opt.id) continue;
            let layer = this.layers.find(l => l.id === opt.id);
            if(!layer) continue;
            let index = this.layers.indexOf(layer);
            const constructor = layer.constructor;
            setProps(opt, layer.props);
            this.layers[index] = new constructor(opt);
        }

        this.deckgl.setProps({layers: [...this.layers]});
    }

    /*
    _updateLayer(layerId, options){
        if(!options || !this._cache[layerId]) return null;
        if(options.id && options.id != layerId) delete options.id;
        if(!this._cache[layerId]) return null;

        let layer = null;
        for(let i = 0; i < this.layers.length; i++){
            let lyr = this.layers[i];
            if(lyr.id == layerId){
                const parameters = Object.assign(this._cache[layerId], options);
                if(lyr instanceof GeoJsonLayer){
                    layer = new GeoJsonLayer(parameters);
                }
                else if(lyr instanceof ScatterplotLayer){
                    layer = new ScatterplotLayer(parameters);
                }
                else if(lyr instanceof TextLayer){
                    // if(!parameters.data) parameters.data = [].concat(parameters.data);
                    layer = new TextLayer(parameters);
                }
                else if(lyr instanceof SolidPolygonLayer){
                    layer = new SolidPolygonLayer(parameters);
                }
                else if(lyr instanceof IconLayer){
                    layer = new IconLayer(parameters);
                }
                else {
                    throw new Error("其他图层类型待实现");
                }
                this._cache[layerId] = parameters;
                this.layers[i] = layer;//更新到图层组缓存中
                break;
            }
        }

        return layer;
    }
    */

    /**
     * 移除图层
     * @param {图层ID} layerId
     */
    removeLayer(layerId){
        const layers = this.layers;
        let array = [];
        for(let i = 0 ; i < layers.length; i++){
            if(layers[i].id == layerId){
                delete this._cache[layerId];
                continue;
            }
            array.push(layers[i]);
        }

        this.deckgl.setProps({layers: array});
    }

    removeLayers(layerIds){
        const layers = this.layers;
        let array = [];

        for(let layer of layers){
            let exist = false;
            for(let id of layerIds){
                if(layer.id == id){
                    exist = true;
                    delete this._cache[id];
                    break;
                }
            }

            if(!exist) array.push(layer);
        }

        // console.log(this._cache)

        this.deckgl.setProps({layers: array});
    }

    /**
     * 删除全部图层
     */
    removeAllLayers(){
        this._cache = {};
        this.deckgl.setProps({layers: []});
    }

    /**
     * 判断图层ID是否已存在
     * @param {*} layerId
     * @returns
     */
    _isLayerIdExisted(layerId){
        const layers = this.layers;
        for(let i = 0 ;i < layers.length; i++){
            if(layers[i].id == layerId) return true;
        }
        return false;
    }
}

/**
 * 用来更新图层属性
 * @param props 新属性
 * @param original 原始属性
 */
function setProps(props, original){
    for(let key in original){
        if(props[key] !== undefined) continue;
        props[key] = original[key];
    }
}
