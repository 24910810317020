import * as mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map from './plus/Map'


mapboxgl.accessToken = 'pk.eyJ1Ijoic2t5b2lvcnkiLCJhIjoiY2pvcGRoMHU4MXFiZTN2bm9jeDJ5aHpieSJ9.MOrtwJ7XswE75ZsDEhGwrQ';




// console.log(mapboxgl);

const {Marker, Point, Popup, LngLat,Evented} = mapboxgl;


export {Map, Marker, Point, Popup, LngLat,Evented };