import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { Layer, project32, gouraudLighting, picking, COORDINATE_SYSTEM } from '@deck.gl/core';
import { Model, Geometry, Transform, hasFeatures, FEATURES } from '@luma.gl/core';
import {Buffer} from '@luma.gl/webgl';
import vsTop from './shaders/solid-polygon-layer-vertex-top.glsl';
import vsSide from './shaders/solid-polygon-layer-vertex-side.glsl';
import fs from './shaders/solid-polygon-layer-fragment.glsl';
import transformShader from './shaders/transform-shader';
const DEFAULT_COLOR = [0, 0, 0, 255];
const defaultProps = {
    filled: true,
    extruded: false,
    wireframe: false,
    _normalize: true,
    _windingOrder: 'CW',
    elevationScale: {
        type: 'number',
        min: 0,
        value: 1
    },
    getElevation: {
        type: 'accessor',
        value: 1000
    },
    getFillColor: {
        type: 'accessor',
        value: DEFAULT_COLOR
    },
    getLineColor: {
        type: 'accessor',
        value: DEFAULT_COLOR
    },
    material: true
};
const ATTRIBUTE_TRANSITION = {
    enter: (value, chunk) => {
        return chunk.length ? chunk.subarray(chunk.length - value.length) : value;
    }
};


export default class JSolidPolygonLayer extends Layer {
    constructor(...args) {
        super(...args);

        _defineProperty(this, "state", void 0);
    }

    getShaders(type) {
        return super.getShaders({
            vs: type === 'top' ? vsTop : vsSide,
            fs,
            defines: {
                RING_WINDING_ORDER_CW: !this.props._normalize && this.props._windingOrder === 'CCW' ? 0 : 1
            },
            modules: [project32, gouraudLighting, picking]
        });
    }

    get wrapLongitude() {
        return false;
    }

    initializeState() {
        const {
            gl,
            viewport
        } = this.context;
        let {
            coordinateSystem
        } = this.props;

        if (viewport.isGeospatial && coordinateSystem === COORDINATE_SYSTEM.DEFAULT) {
            coordinateSystem = COORDINATE_SYSTEM.LNGLAT;
        }

        this.setState({
            numInstances: 0,
            vTransform: new Transform(gl, {
                vs: transformShader,
                defines: {
                    RING_WINDING_ORDER_CW: !this.props._normalize && this.props._windingOrder === 'CCW' ? 0 : 1
                },
                modules: [project32],
                sourceBuffers: {
                    position: new Buffer(gl, new Float32Array([0.0, 0.0]))
                },
                uniforms:{
                    'brushing_mousePos':[0, 0]
                },
                feedbackMap: {
                    position: 'vPosition'
                },
                elementCount: 1
            }),
            vTransform2: new Transform(gl, {
                vs: transformShader,
                defines: {
                    RING_WINDING_ORDER_CW: !this.props._normalize && this.props._windingOrder === 'CCW' ? 0 : 1
                },
                modules: [project32],
                sourceBuffers: {
                    position: new Buffer(gl, new Float32Array([0.0, 0.0]))
                },
                uniforms:{
                    'brushing_mousePos':[0, 0]
                },
                feedbackMap: {
                    position: 'vPosition'
                },
                elementCount: 1
            })
        });
        const attributeManager = this.getAttributeManager();
        const noAlloc = true;
        attributeManager.remove(['instancePickingColors']);
        attributeManager.add({
            indices: {
                size: 1,
                isIndexed: true,
                // update: this.calculateIndices,
                noAlloc
            },
            positions: {
                size: 3,
                type: 5130,
                fp64: this.use64bitPositions(),
                transition: ATTRIBUTE_TRANSITION,
                // accessor: 'getPolygon',
                // update: this.calculatePositions,
                noAlloc,
                shaderAttributes: {
                    positions: {
                        vertexOffset: 0,
                        divisor: 0
                    },
                    instancePositions: {
                        vertexOffset: 0,
                        divisor: 1
                    },
                    nextPositions: {
                        vertexOffset: 1,
                        divisor: 1
                    }
                }
            },
            vertexValid: {
                size: 1,
                divisor: 1,
                type: 5121,
                // update: this.calculateVertexValid,
                noAlloc
            },
            elevations: {
                size: 1,
                transition: ATTRIBUTE_TRANSITION,
                accessor: 'getElevation',
                shaderAttributes: {
                    elevations: {
                        divisor: 0
                    },
                    instanceElevations: {
                        divisor: 1
                    }
                }
            },
            fillColors: {
                size: this.props.colorFormat.length,
                type: 5121,
                normalized: true,
                transition: ATTRIBUTE_TRANSITION,
                accessor: 'getFillColor',
                defaultValue: DEFAULT_COLOR,
                shaderAttributes: {
                    fillColors: {
                        divisor: 0
                    },
                    instanceFillColors: {
                        divisor: 1
                    }
                }
            },
            lineColors: {
                size: this.props.colorFormat.length,
                type: 5121,
                normalized: true,
                transition: ATTRIBUTE_TRANSITION,
                accessor: 'getLineColor',
                defaultValue: DEFAULT_COLOR,
                shaderAttributes: {
                    lineColors: {
                        divisor: 0
                    },
                    instanceLineColors: {
                        divisor: 1
                    }
                }
            },
            pickingColors: {
                size: 3,
                type: 5121,
                accessor: (object, {
                    index,
                    target: value
                }) => {
                    return this.encodePickingColor(object && object.__source ? object.__source.index : index, value)
                },
                shaderAttributes: {
                    pickingColors: {
                        divisor: 0
                    },
                    instancePickingColors: {
                        divisor: 1
                    }
                }
            }
        });
    }

    encodePickingColor(i, target) {
        if (!target || target.length == 0) target = [0, 0, 0];
        target[0] = (i + 1) & 255;
        target[1] = ((i + 1) >> 8) & 255;
        target[2] = (((i + 1) >> 8) >> 8) & 255;
        return target;
    }

    getPickingInfo(params) {
        const info = super.getPickingInfo(params);
        const {
            index
        } = info;
        const {
            data
        } = this.props;
        if(index < 0) return info;

        let start = data.vertexStarts[index];
        let end = data.vertexStarts[index + 1];

        let bytearray = data.attributes.positions.subarray(start * 3, end * 3);

        /****特别处理***/
        let title = null;
        start = index * 7;
        end = start + 7;

        if(start < data.properties.length && end < data.properties.length){
            title = data.properties.substring(start, end);
        }

        info.object = {
            positions: bytearray,
            title
        };
        //
        // if (data[0] && data[0].__source) {
        //     info.object = data.find(d => d.__source.index === index);
        // }

        return info;
    }

    disablePickingIndex(objectIndex) {
        const {
            data
        } = this.props;

        if (data[0] && data[0].__source) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].__source.index === objectIndex) {
                    this._disablePickingIndex(i);
                }
            }
        } else {
            this._disablePickingIndex(objectIndex);
        }
    }

    draw({
             uniforms
         }) {
        const {
            extruded,
            filled,
            wireframe,
            elevationScale,
            data
        } = this.props;

        const {
            topModel,
            sideModel,
            vTransform,
            vTransform2
        } = this.state;

        const renderUniforms = {
            ...uniforms,
            extruded: Boolean(extruded),
            elevationScale
        };

        if (sideModel) {
            sideModel.setInstanceCount(data.instanceCount || 0 - 1);
            sideModel.setUniforms(renderUniforms);

            if (wireframe) {
                sideModel.setDrawMode(3);
                sideModel.setUniforms({
                    isWireframe: true
                }).draw();
            }

            if (filled) {
                sideModel.setDrawMode(6);
                sideModel.setUniforms({
                    isWireframe: false
                }).draw();
            }
        }

        if (topModel) {
            topModel.setVertexCount(data.vertexCount || 0);
            topModel.setUniforms(renderUniforms);

            // vTransform.model.setUniforms(topModel.uniforms);
            // vTransform2.model.setUniforms(topModel.uniforms);
            // vTransform.model.vertexArray.unused = {};
            // vTransform.model.vertexArray.attributes = {};
            // vTransform.model.vertexArray.elements = null;
            // vTransform.model.vertexArray.elementsAccessor = null;
            // vTransform.uniforms = topModel.uniforms;
            // vTransform.model.setUniforms({
            //     brushing_mousePos:[118.022162, 32.1842]
            // });
            // vTransform.run();
            // vTransform2.run();
            // // console.log(vTransform.model, vTransform2.model);
            // // console.log(vTransform.getBuffer('vPosition').getData(), vTransform2.getBuffer('vPosition').getData());
            // console.log(vTransform.model, vTransform.getBuffer('vPosition').getData());

            // topModel.setUniforms({brushing_mousePos: vTransform.getBuffer('vPosition').getData()});
            // console.log(vTransform, vTransform.getBuffer('vPosition').getData());
            // console.log(topModel)
            topModel.draw();
            // console.log("p",topModel.uniforms.project_uModelMatrix)

        }

    }

    updateState(updateParams) {
        super.updateState(updateParams);

        this.updateGeometry(updateParams);
        const {
            props,
            oldProps,
            changeFlags
        } = updateParams;
        const attributeManager = this.getAttributeManager();
        const regenerateModels = changeFlags.extensionsChanged || props.filled !== oldProps.filled || props.extruded !== oldProps.extruded;

        if (regenerateModels) {
            var _this$state$models;

            (_this$state$models = this.state.models) === null || _this$state$models === void 0 ? void 0 : _this$state$models.forEach(model => model.delete());
            let models = this._getModels(this.context.gl);
            this.setState(models);
            attributeManager.invalidateAll();
        }

    }

    updateGeometry({
                       props,
                       oldProps,
                       changeFlags
                   }) {
        const geometryConfigChanged = changeFlags.dataChanged || changeFlags.updateTriggersChanged && (changeFlags.updateTriggersChanged.all);

        if (geometryConfigChanged) {
            const buffers = props.data.attributes || {};
            const data = props.data || {};

            this.setState({
                numInstances: data.vertexCount || 0,
                startIndices: data.vertexStarts || [0]
            });


            if (!changeFlags.dataChanged) {
                this.getAttributeManager().invalidateAll();
            }

            const { indices, vertexValid, positions } = this.state.attributeManager.attributes;
            indices.startIndices = data.indexStarts || [0];
            indices.value = buffers.indices || new Uint32Array(1);
            positions.startIndices = data.vertexStarts || [0];
            positions.value = buffers.positions || new Float32Array(1);
            vertexValid.value = buffers.vertexValid || new Uint8ClampedArray(1);
        }
    }

    _getModels(gl) {
        const {
            id,
            filled,
            extruded
        } = this.props;

        const { vTransform } = this.state;

        let topModel;
        let sideModel;

        if (filled) {
            const shaders = this.getShaders('top');
            shaders.defines.NON_INSTANCED_MODEL = 1;
            topModel = new Model(gl, {
                ...shaders,
                id: "".concat(id, "-top"),
                drawMode: 4,
                attributes: {
                    vertexPositions: new Float32Array([0, 1])
                },
                uniforms: {
                    isWireframe: false,
                    isSideVertex: false
                },
                vertexCount: 0,
                isIndexed: true
            });
        }

        if (extruded) {
            sideModel = new Model(gl, {
                ...this.getShaders('side'),
                id: "".concat(id, "-side"),
                geometry: new Geometry({
                    drawMode: 1,
                    vertexCount: 4,
                    attributes: {
                        vertexPositions: {
                            size: 2,
                            value: new Float32Array([1, 0, 0, 0, 0, 1, 1, 1])
                        }
                    }
                }),
                instanceCount: 0,
                isInstanced: 1
            });
            sideModel.userData.excludeAttributes = {
                indices: true
            };
        }
        return {
            models: [sideModel, topModel, vTransform.model].filter(Boolean),
            topModel,
            sideModel
        };
    }
}

_defineProperty(JSolidPolygonLayer, "defaultProps", defaultProps);

_defineProperty(JSolidPolygonLayer, "layerName", 'JSolidPolygonLayer');
