<template>
  <div class="datapad-btn map-control-background">
    <el-tooltip class="box-item" effect="dark" content="加载本地数据" placement="left">
      <el-button @click="dialogOpened=true"><el-icon><FolderOpen20Filled /></el-icon></el-button>
    </el-tooltip>
  </div>

  <el-dialog
      v-model="dialogOpened"
      title="选择本地文件"
      width="600px"
      align-center
  >
    <p style="margin-top: -25px; font-size:13px; ">支持geojson,shp及geotiff文件</p>
    <div class="file-drag-area"
         draggable=”true”
         droppable="true"
         @click="onClickRectangle"
         @dragEvent="dragEvent"
         @dragover="dragEvent"
         @dragleave="dragEvent"
         @drop="onDrop"
    >
      <input @change="onChangeFile" type="file" ref="fileInput" style="display:none" accept=".geojson,.shp,.tif,.tiff" />
      <el-icon :size="70" style="margin-top: 20px;"><Document /></el-icon>
      <p style="cursor:inherit;">将文件拖拽到此区域或 <em style="color:rgb(64, 158, 255);">点击区域选择文件</em></p>
    </div>
  </el-dialog>
</template>

<script>
import { Document } from '@element-plus/icons-vue'
import { FolderOpen20Filled } from '@vicons/fluent'
import { ElMessage } from 'element-plus'

import { addGeoJsonLayer,showShpLayer } from '@/scripts/custom/CustomDataManage';
import {showTiffLayer} from '@/scripts/custom/RasterTiffLayer';
import { renderSpectraLayer } from '@/scripts/custom/SpectraTiffLayer';

const reader = new FileReader();
export default {
  name: "LoadFiles",
  components: { Document, FolderOpen20Filled },
  data(){
    return {
      dialogOpened: false
    }
  },
  methods:{
    onClickRectangle(){
      this.$refs.fileInput.click();
    },
    onChangeFile(event){
      // console.log(this.$refs.fileInput.files, 'files');
      this._loadFile(this.$refs.fileInput.files[0]);
    },
    dragEvent(event){
      event.preventDefault();
    },
    onDrop(event){
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      const filename = file.name.toLowerCase();
      if(!filename.endsWith(".geojson") && !filename.endsWith(".shp") && !filename.endsWith(".tiff")){
        ElMessage({
          message: '不支持该类型的文件',
          type: 'error',
        })
        return;
      }
      this._loadFile(file);
    },
    _loadFile(file){
      this.dialogOpened = false;
      if(file.name.toLowerCase().endsWith(".geojson")){
        reader.onload = function(f) {
          try{
            const geojson = JSON.parse(reader.result);
            addGeoJsonLayer(geojson);
          }
          catch (err){
            ElMessage({
              message: err.message,
              type: 'error',
            })
          }
        }
        reader.onerror = function(err){
          ElMessage({
            message: err.message,
            type: 'error',
          })
        }
        reader.readAsText(file);
      }
      else if(file.name.toLowerCase().endsWith(".shp")){
        reader.onload = function(f) {
          try{
            showShpLayer(reader.result);
          }
          catch (err){
            ElMessage({
              message: err.message,
              type: 'error',
            })
          }
        }
        reader.onerror = function(err){
          ElMessage({
            message: err.message,
            type: 'error',
          })
        }
        reader.readAsArrayBuffer(file);
      }
      else if(file.name.toLowerCase().endsWith(".tiff") || file.name.toLowerCase().endsWith(".tif")){
        reader.onload = function(f) {
          try{
            showTiffLayer(reader.result);
            // const renderconfig = [[0, 153, 204], -0.7, [255, 204, 102], -0.1, [102, 51, 204], 0.5, [255, 51, 102]];
            // renderSpectraLayer(reader.result, renderconfig);
          }
          catch (err){
            ElMessage({
              message: err.message,
              type: 'error',
            })
          }
        }
        reader.onerror = function(err){
          ElMessage({
            message: err.message,
            type: 'error',
          })
        }
        reader.readAsArrayBuffer(file);
      }
    }
  }
}
</script>

<style scoped>
.datapad-btn{
  position: absolute;
  right:5px;
  top: 45px;
  width:auto;
  height:auto;
  padding:0px;
  margin:0px;
  border-radius:8px;
  z-index:2;
}

.datapad-btn  > button{
  display:block;
  font-size:23px;
  padding:5px 5px;
  border-radius: inherit;
  margin:0px 0px;
}

.file-drag-area{
  width:100%;
  height:200px;
  border:1px dashed white;
  border-radius: 5px;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 10px;
  cursor:pointer;
}

.file-drag-area:hover{
  border-color:#00FFFF;
}

</style>
