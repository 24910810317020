<template>
  <div class="datapad-btn map-control-background">
    <el-tooltip class="box-item" effect="dark" content="打开数据面版" placement="left">
      <el-button @click="drawer=true"><el-icon><Layer20Filled /></el-icon></el-button>
    </el-tooltip>
  </div>
  <el-drawer v-model="drawer" @closed="onClosed">
    <template #header>
      <h4>选择加载数据</h4>
    </template>
    <template #default>
      <div>
        <div v-for="(item, index) in dataList" class="data-item-container" @click="changeSelect(index)">
          <img :src="item.src" />
          <span class="title">{{item.name}}</span>
          <el-icon :class="{'data-item-selectIcon':true, 'hidden': index != selectData}"><SuccessFilled /></el-icon>
        </div>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="ok">确定</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import {SuccessFilled} from '@element-plus/icons-vue'
import { Layer20Filled } from '@vicons/fluent'
import { ElLoading } from 'element-plus'

import { ElNotification } from 'element-plus'

import {installModule,uninstallModule} from '@/libs/map/MapHelper'
import * as MapDraw from '@/scripts/MapDraw';
import * as Buildings from '@/scripts/Buildings';
import * as Radar from '@/scripts/RadarSample';
import * as LD from '@/scripts/LDSample';

export default {
  name: "DataPad",
  components: {Layer20Filled,SuccessFilled},
  data(){
    return {
      drawer:false,
      dataList:[
        {
          name: "全国1:5W格网面",
          src: "thumbnails/tufu.jpg"
        },
        {
          name: "益阳市楼栋面",
          src: "thumbnails/loudong.jpg"
        },
        {
          name: "湖北林地小班",
          src: "thumbnails/loudong.jpg"
        },
        // {
        //   name: "雷达扫描",
        //   src: "thumbnails/loudong.jpg"
        // }
      ],
      currentData: -1,
      selectData: -1
    }
  },
  methods:{
    ok(){
      this.drawer = false;
      if(this.selectData != this.currentData){
        if(this.currentData == 0){
          uninstallModule(MapDraw);
        }
        else if(this.currentData == 1){
          uninstallModule(Buildings);
        }
        else if(this.currentData == 2){
          uninstallModule(LD);
        }

        this.currentData = this.selectData;

        if(this.currentData == 0){
          const loader = ElLoading.service({
            lock: true,
            // target: document.getElementById("map"),
            text: '正在加载数据',
            background: 'rgba(0, 0, 0, 0.7)',
            customClass:'map-loading'
          });

          installModule(MapDraw,(result)=>{
            loader.close();
            this.showResult(result)
          });
          this.$store.commit("module", MapDraw);


        }
        else if(this.currentData == 1){
          const loader = ElLoading.service({
            lock: true,
            // target: document.getElementById("map"),
            text: '正在加载数据',
            background: 'rgba(0, 0, 0, 0.7)',
            customClass:'map-loading'
          });

          installModule(Buildings, (result)=>{
            loader.close()
            this.showResult(result)
          });
          this.$store.commit("module", Buildings);
        }
        else if(this.currentData == 2){
          const loader = ElLoading.service({
            lock: true,
            // target: document.getElementById("map"),
            text: '正在加载数据',
            background: 'rgba(0, 0, 0, 0.7)',
            customClass:'map-loading'
          });
          installModule(LD,(result)=>{
            loader.close()
            this.showResult(result)
          });
          this.$store.commit("module", LD);
        }
        else{
          this.$store.commit("module", null);
        }
      }

    },
    cancel(){
      this.drawer = false;
      this.selectData = this.currentData;

    },
    onClosed(){
      if(this.selectData != this.currentData) this.selectData = this.currentData;
    },
    changeSelect(index){
      if(this.selectData != index) this.selectData = index;
      else this.selectData = -1;
    },
    showResult(result){
      let total = result.total.toString();
      let str = "";
      let length = total.length;
      while(length > 0){
        if(str) str = "," + str;
        for(let i = 0; i < 3 && length > 0; i++){
          str = total[--length] + str;
        }
      }

      let message = `<p><strong>总量：<i style="color:#CCFFCC">${str}</i></strong></p><p><strong>用时：<i style="color:#CCFFCC">${(result.duration / 1000).toFixed(1)}</i> 秒</strong></p>`;
      if(result.speed){
        message += `<p><strong>传输：<i style="color:#CCFFCC">${result.speed} M/s</i></strong></p>`;
      }
      ElNotification({
        title: '加载完成',
        dangerouslyUseHTMLString: true,
        message: message,
        type: 'success',
        duration: 10000
      })
    }
  }
}
</script>

<style scoped>
.datapad-btn{
  position: absolute;
  right:5px;
  top: 5px;
  width:auto;
  height:auto;
  padding:0px;
  margin:0px;
  border-radius:8px;
  z-index:2;
}

.datapad-btn  > button{
  display:block;
  font-size:23px;
  padding:5px 5px;
  border-radius: inherit;
  margin:0px 0px;
}

h4{
  margin:0px 0px;
}

.data-item-container{
  width:calc(50% - 25px);
  height:150px;
  text-align:center;
  margin:0px 10px 40px 10px;
  display:inline-block;
  box-sizing: content-box;
  border-radius: 10px;
  cursor: pointer;
}

.data-item-container > img{
  width:100%;
  height:calc(100% - 25px);
  border-radius: inherit;
  position:relative;
  /*border:2px solid rgba(0,0,0,0);*/
  box-sizing:border-box;
}
.data-item-container > span.title{
  position:relative;
  display:block;
  z-index:2;
  font-size:13px;
}

.data-item-container > .data-item-selectIcon{
  position:relative;
  color:#85ce61;
  font-size:20px;
  top:calc(-100% + 8px);
  left:calc(50% - 15px);
  background-color:rgb(255,255,255);
  border-radius:50%;
  box-sizing:border-box;
}

.data-item-container > .data-item-selectIcon.hidden{
  color: rgba(255,255,255,0);
  background:none;
  border:1px solid #fff;
}

.data-item-container:hover > img{
  border:2px solid #FFFF00;
}

</style>
