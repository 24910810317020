<template>
  <div class="basic-layers map-control-background">
    <BasicLayerItem v-for="item of groups" :src="item.icon" :class="{selected:currentGroup==item.name}" :text="item.title" @clickBasicLayerItem="changeBasicLayerGroup" />
  </div>
</template>

<script>
import BasicLayerItem from "@/components/map/BasicLayerItem";

import vectorIcon from '@/assets/images/vector.jpg'
import imageIcon from '@/assets/images/image.jpg'
import terIcon from '@/assets/images/ter.jpg'
import osmIcon from '@/assets/images/osm.jpg'

import { getMap } from '@/libs/map/MapHelper'


export default {
  name: "BasicLayerSelector",
  components:{BasicLayerItem},
  data(){
    return {
      groups:[
        {icon: vectorIcon, title: "矢量", name: "vector"},
        {icon: imageIcon, title: "影像", name: "image"},
        {icon: terIcon, title: "地形", name: "ter"},
        {icon: osmIcon, title: "OSM", name: "osm"},
      ],
      currentGroup: "vector"
    }
  },
  methods:{
    changeBasicLayerGroup(text){
      if(text == "影像"){
        this.currentGroup = "image";
        getMap().changeBasicLayerGroup("image");
      }
      else if(text == "矢量"){
        this.currentGroup = "vector";
        getMap().changeBasicLayerGroup("vector");
      }
      else if(text == "地形"){
        this.currentGroup = "ter";
        getMap().changeBasicLayerGroup("ter");
      }else if(text == "OSM"){
        this.currentGroup = "osm";
        getMap().changeBasicLayerGroup("osm");
      }
    }
  }
}
</script>

<style scoped>
  .basic-layers{
    position: absolute;
    width:80px;
    height:60px;
    bottom:5px;
    right:5px;
    z-index: 2;
    border-radius: 3px;
    transition:width 0.3s;
  }

  .basic-layers:hover{
    width:305px;
  }

  .basic-layers > div{
    transition:left 0.3s;
  }

  .basic-layers > div.selected{
    border:1px solid #33FF99;
    top:4px;
    z-index:2;
  }

  .basic-layers:hover > div:nth-child(2) {
    left: 80px;
  }
  .basic-layers:hover > div:nth-child(3){
    left:155px;
  }
  .basic-layers:hover > div:nth-child(4){
    left:230px;
  }

</style>
