export default
`\
#version 300 es

uniform vec2 brushing_mousePos;

// in vec2 position;

out vec2 vPosition;
void main() {
    vPosition = project_position(brushing_mousePos);
}
`;