
function onMousePosition(event){
    const lnglat = event.lngLat;
    const control = event.target._positionControl;
    if(control){
        control.setPositionText(lnglat);
    }
}

/**
 * 鼠标位置显示
 */
export default class MousePositionControl {
    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.style.position = "absolute";
        this._container.style.width = "230px";
        this._container.style.height = "0px";
        this._container.style.bottom = "65px";
        // this._container.style.right = map._container.offsetWidth / 2 - 200 + "px";
        this._container.style.left = "10px";
        this._container.style.textAlign = "left";
        const span = document.createElement('span');
        span.className = "map-control-background";
        span.style.display = "inline-block";
        span.style.width = "auto";
        span.style.height = "auto";
        // span.style.backgroundColor = "rgba(255,255,255,0.75)";
        span.style.padding = "2px 5px";
        span.style.borderRadius = "3px";
        span.style.fontSize = "13px";
        span.style.border = "solid 1px #aaa";
        this._container.appendChild(span);
        this._span = span;
        map._positionControl = this;
        map.on('mousemove', onMousePosition);

        return this._container;
    }

    onRemove() {
        this._map.off('mousemove', onMousePosition);
        delete this._map._positionControl;
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    setPositionText(lnglat){
        this._span.textContent = lnglat.lng.toFixed(6) + " , " + lnglat.lat.toFixed(6);
    }
}
