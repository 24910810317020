import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { Layer, project32, UNIT } from '@deck.gl/core';
import { Model, Geometry,Transform } from '@luma.gl/core';
import {Buffer} from '@luma.gl/webgl';
const DEFAULT_COLOR = [0, 0, 0, 255];
const defaultProps = {
    auxiliaryPoint: [0, 0]
};

const ts = `\
#version 300 es

uniform vec2 aux_point;

out vec2 vPosition;
void main() {
    vPosition = project_position(aux_point);
}
`;


export default class AuxiliaryLayer extends Layer {

    initializeState() {
        const {
            gl
        } = this.context;
        const transform = new Transform(gl, {
            vs: ts,
            defines: {
                RING_WINDING_ORDER_CW: 1
            },
            modules: [project32],
            sourceBuffers: {
                position: new Buffer(gl, new Float32Array([0.0, 0.0]))
            },
            uniforms:{
                'aux_point': this.props.auxiliaryPoint
            },
            feedbackMap: {
                position: 'vPosition'
            },
            elementCount: 1
        });
        this.setState({
            transform,
            model: transform.model,
            commonPosition:[99999,99999]
        });
    }

    // updateState(params) {
    //     super.updateState(params);
    //
    //     if (params.changeFlags.extensionsChanged) {
    //
    //         const { transform } = this.state;
    //         transform.model.setUniforms({});
    //
    //         var _this$state$model;
    //
    //         const {
    //             gl
    //         } = this.context;
    //         (_this$state$model = this.state.model) === null || _this$state$model === void 0 ? void 0 : _this$state$model.delete();
    //         this.state.model = this._getModel(gl);
    //         this.getAttributeManager().invalidateAll();
    //     }
    // }

    draw({
             uniforms
         }) {
        const { transform } = this.state;
        transform.model.setUniforms(uniforms);
        transform.model.vertexArray.unused = {};
        transform.model.vertexArray.attributes = {};
        transform.model.vertexArray.elements = null;
        transform.model.vertexArray.elementsAccessor = null;
        transform.run();
        this.setState({
            commonPosition: transform.getBuffer('vPosition').getData()
        });
        // console.log(uniforms, this.state)
        // console.log(transform.getBuffer('vPosition').getData(),transform.model);
    }

    getCommonPosition(){
        return this.state.commonPosition;
    }
}

_defineProperty(AuxiliaryLayer, "defaultProps", defaultProps);

_defineProperty(AuxiliaryLayer, "layerName", 'AuxiliaryLayer');