const LAYERID = "drawpolygonhandlelyr";

let points = null;
let instance = null;
let map = null;

function onMapClick(event){
    points.push([event.lngLat.lng, event.lngLat.lat]);
}

// function onMapDblClick(event){
//     console.log(event);
// }

/**
 * 结束绘制
 */
function onContextmenu(){
    if(points.length < 3){
        points = [];
        map.updateDeckLayer(LAYERID, { data: points });
        map.fire("drawend", {geometry: null});
    }
    else{
        const geometry = {
            type: "Polygon",
            coordinates: [[points, [points[0]]].flat()]
        }
        let features = [{
            type: "Feature",
            geometry
        }];
        map.updateDeckLayer(LAYERID, { data: features, getLineColor: [0, 204, 255]});
        map.fire("drawend", {geometry});
    }
    setTimeout(() => {instance.deactivate()}, 1);
}


function onMouseMove(event){
    let newPoint = [event.lngLat.lng, event.lngLat.lat];

    let features = [];

    if(points.length == 1){//绘线
        features.push({
            type: "Feature",
            geometry:{
                type: "LineString",
                coordinates: [points[0], newPoint]
            }
        });
    }
    else if(points.length > 1){ //绘面
        features.push({
            type: "Feature",
            geometry:{
                type: "Polygon",
                coordinates: [[points, [newPoint, points[0]]].flat()]
            }
        });
    }

    features.push({
        type:"Feature",
        geometry: {
            type: "Point",
            coordinates: [event.lngLat.lng, event.lngLat.lat]
        }
    });

    map.updateDeckLayer(LAYERID, { data: features, getLineColor: [255, 204, 0]});
}


/**
 * 绘制多边形
 * 单例
 */
export default class DrawPolygon {
    constructor(map) {
        this._isActivated = false;
        this._map = map;

        this._points = [];
    }

    get isActivated(){
        return this._isActivated;
    }

    activate(){
        if(this._isActivated) return;
        this._isActivated = true;
        map = this._map;
        instance = this;
        points = this._points;
        this._map.on("click", onMapClick);
        this._map.on("mousemove", onMouseMove);
        this._map.on("contextmenu",onContextmenu);
        // this._map.on("dblclick", onMapDblClick);

        this._map.addDeckGeoJsonLayer({
            id: LAYERID,
            pickable: false,
            stroked: true,
            filled: true,
            extruded: false,
            pointType: 'circle',
            lineWidthUnits: "pixels",
            pointRadiusUnits: "pixels",
            lineWidthScale: 1,
            lineWidthMinPixels: 2,
            getFillColor: [255, 255, 255, 50],
            getLineColor: [0, 204, 255],
            getPointRadius: 5,
            getLineWidth: 2,
            getElevation: 30
        });

        let container = this._map.getCanvas().parentElement;
        container.style.cursor = "default";
    }


    deactivate(){
        if(!this._isActivated) return;
        this._isActivated = false;
        this._map.off("click", onMapClick);
        this._map.off("mousemove", onMouseMove);
        this._map.off("contextmenu",onContextmenu);
        // this._map.off("dblclick", onMapDblClick);
        map = null;
        instance = null;
        points = null;
        this._points.length = 0;

        let container = this._map.getCanvas().parentElement;
        container.style.cursor = null;
    }

    /**
     * 清除图层
     */
    clean(){
        this._map.removeDeckLayer(LAYERID);
        this.deactivate();
    }
}
