import LengthMeasurer from "./measurers/length";
import AreaMeasurer from "./measurers/area";

export default class MeasurerManager {
    constructor(map) {
        this.map = map;
        this._actived = false;

        this._length = new LengthMeasurer(map);
        this._area = new AreaMeasurer(map);
    }

    /**
     * 激活测量控件
     * 1:长度, 2: 面积
     */
    activate(type){
        if(this._actived != type){
            if(this._actived === 1) this._length.clean();
            else if(this._actived === 2) this._area.clean();
        }

        if(type === 1 && !this._length.isActivated){
            this._length.activate();
        }
        else if(type === 2 && !this._area.isActivated){
            this._area.activate();
        }

        this._actived = type;
    }

    /**
     * 反激活
     */
    deactivate(){
        if(this._actived){
            this._actived = false;

            this._length.deactivate();
            this._area.deactivate();
        }
    }

    clean(){
        this._length.clean();
        this._area.clean();
    }
}
