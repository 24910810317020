const LAYERID = "dragrectanglehandlelyr";

let startPoint;
let instance = null;
let map = null;


function onMapClick(event){
    let point = [event.lngLat.lng, event.lngLat.lat];
    if(!startPoint){ //开始
        startPoint = point;
    }
    else{ //结束
        const features = []
        let coordinates = [startPoint];
        coordinates.push([point[0], startPoint[1]]);
        coordinates.push(point);
        coordinates.push([startPoint[0], point[1]]);
        coordinates.push(startPoint);

        const geometry = {
            type: "Polygon",
            coordinates: [coordinates]
        };
        features.push({
            type: "Feature",
            geometry
        });
        map.updateDeckLayer(LAYERID, { data: features, getLineColor: [0, 204, 255]});
        map.fire("drawend", {geometry});
        instance.deactivate();
    }
}


function onMouseMove(event){
    const point = [event.lngLat.lng, event.lngLat.lat];
    const features = [{
        type: "Feature",
        geometry:{
            type: "Point",
            coordinates: point
        }
    }]

    if(startPoint){
        let coordinates = [startPoint];
        coordinates.push([point[0], startPoint[1]]);
        coordinates.push(point);
        coordinates.push([startPoint[0], point[1]]);
        coordinates.push(startPoint);

        features.push({
            type: "Feature",
            geometry:{
                type: "Polygon",
                coordinates: [coordinates]
            }
        });
    }
    map.updateDeckLayer(LAYERID, { data: features, getLineColor: [255, 204, 0]});
}


/**
 * 拉框绘制矩形
 */
export default class DrawRectangle {
    constructor(map) {
        this._isActivated = false;
        this._map = map;
        // this._dragging = false;
    }

    get isActivated(){
        return this._isActivated;
    }

    activate(){
        if(this._isActivated) return;
        this._isActivated = true;
        map = this._map;
        instance = this;
        this._map.on("mousemove", onMouseMove);
        this._map.on("click", onMapClick);
        // this._map.dragPan.disable();

        this._map.addDeckGeoJsonLayer({
            id: LAYERID,
            pickable: false,
            stroked: true,
            filled: true,
            extruded: false,
            pointType: 'circle',
            lineWidthUnits: "pixels",
            pointRadiusUnits: "pixels",
            lineWidthScale: 1,
            lineWidthMinPixels: 2,
            getFillColor: [255, 255, 255, 50],
            getLineColor: [0, 204, 255],
            getPointRadius: 5,
            getLineWidth: 2,
            getElevation: 30
        });

        let container = this._map.getCanvas().parentElement;
        container.style.cursor = "default";

    }


    deactivate(){
        if(!this._isActivated) return;
        this._isActivated = false;
        this._map.off("mousemove", onMouseMove);
        this._map.off("click", onMapClick);
        map = null;
        instance = null;
        startPoint = null;

        // this._map.dragPan.enable();

        let container = this._map.getCanvas().parentElement;
        container.style.cursor = null;
    }

    /**
     * 清除图层
     */
    clean(){
        this._map.removeDeckLayer(LAYERID);
        this.deactivate();
    }
}
