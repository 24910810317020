import * as turf from '@turf/turf';
import {SHPLoader, DBFLoader} from '@loaders.gl/shapefile';
import { getMap} from '@/libs/map/MapHelper';

const CUSTOM_LAYER_ID = "custom_layer_id";
let layerIndex = 0;
function addGeoJsonLayer(geojson){
    const map = getMap();
    if(!map) return;
    map.removeDeckLayer(CUSTOM_LAYER_ID);
    // const r = parseInt(Math.random() * 255);
    // const g = parseInt(Math.random() * 255);
    // const b = parseInt(Math.random() * 255);
    map.addDeckGeoJsonLayer({
        id: CUSTOM_LAYER_ID,
        data: geojson,
        pickable: false,
        stroked: true,
        filled: true,
        extruded: false,
        pointType: 'circle',
        lineWidthUnits: "pixels",
        pointRadiusUnits: "pixels",
        lineWidthScale: 1,
        lineWidthMinPixels: 2,
        getFillColor: [255, 255, 255, 100],
        // getLineColor: [0, 204, 255],
        getLineColor: [255,102,51],
        getPointRadius: 5,
        getLineWidth: 2
    });

    const bbox = turf.bbox(geojson);
    const [minX, minY, maxX, maxY] = bbox;
    map.fitBounds([[minX, maxY], [maxX, minY]],
        {padding: {top: 10, bottom:25, left: 15, right: 15}}
    );
}

async function showShpLayer(arraybuffer){
    const map = getMap();
    if(!map) return;
    const shp_content = await SHPLoader.parse(arraybuffer, {worker: true});
    const {geometries, header} = shp_content;
    const {bbox, type} = header;
    const {minX, maxX, minY, maxY} = bbox;
    map.fitBounds([[minX, maxY], [maxX, minY]],
        {padding: {top: 10, bottom:25, left: 15, right: 15}}
    );

    map.removeDeckLayer(CUSTOM_LAYER_ID);
    if(type == 5 || type == 15 || type == 25){//多边形
        map.addDeckPolygonLayer({
            id: CUSTOM_LAYER_ID,
            data: geometries,
            pickable: false,
            stroked: true,
            filled: true,
            getPolygon: (d) => {
                const {polygonIndices, positions} = d;
                const points = [];
                for(let i = 0; i < positions.value.length; i+= positions.size){
                    points.push(positions.value.subarray(i, i + positions.size));
                }
                const polygon = [];
                for(let i = 0; i < polygonIndices.value.length - 1; i++){
                    const ring = [];
                    let index = polygonIndices.value[i];
                    const end = polygonIndices.value[i+1];
                    for(;index < end; index++){
                        ring.push(points[index]);
                    }
                    polygon.push(ring);
                }
                return polygon;
            },
            getColor: [255, 0, 0],
            extruded: false,
            lineWidthUnits: "pixels",
            lineWidthScale: 1,
            lineWidthMinPixels: 2,
            getFillColor: [255, 255, 255, 100],
            getLineColor: [255, 102, 51],
            getLineWidth: 2
        });
    }
    else{
        throw new Error("暂仅支持面要素");
    }
}

export {  addGeoJsonLayer,showShpLayer }
