import * as turf from '@turf/turf';

const LAYERID = "measurelengthhandlelyr";

var options = { units: 'kilometers' };

let points = null;
let instance = null;
let map = null;

function onMapClick(event){
    let point = turf.point([event.lngLat.lng, event.lngLat.lat]);
    if(points.length == 0){
        point.properties = {
            text: "0",
            length: 0
        }
    }
    else {
        let prePoint = points[points.length - 1];
        let distance = turf.distance(point, points[points.length - 1], options) + prePoint.properties.length;
        point.properties = {
            text: distance < 1 ? `${parseInt(Math.round(distance * 1000))} m` :  `${distance.toFixed(2)} km`,
            length: distance
        }
    }
    points.push(point);
}

/**
 * 结束绘制
 */
function onContextmenu(){
    if(points.length < 2){
        points = [];
        map.updateDeckLayer(LAYERID, { data: points });
    }
    else{

        let features = [{
            type: "Feature",
            geometry:{
                type: "LineString",
                coordinates: points.map(p => p.geometry.coordinates)
            }
        }];

        features = features.concat(points);

        map.updateDeckLayer(LAYERID, { data: features, getLineColor: [0, 204, 255]});
    }
    setTimeout(() => {instance.deactivate()}, 1);

    map.fire("measureend");
}


function onMouseMove(event){

    let features = [];
    let point = turf.point([event.lngLat.lng, event.lngLat.lat]);

    if(points.length == 0){
        point.properties = {
            text: "0",
            length: 0
        }
        features.push(point);
    }
    else {

        let prePoint = points[points.length - 1];
        let distance = turf.distance(point, points[points.length - 1], options) + prePoint.properties.length;

        point.properties = {
            text: distance < 1 ? `${parseInt(Math.round(distance * 1000))} m` :  `${distance.toFixed(2)} km`,
            length: distance
        }

        features = features.concat(points);
        features.push(point);

        let coordinates = features.map(p => p.geometry.coordinates);
        features.push({
            type: "Feature",
            geometry:{
                type: "LineString",
                coordinates: coordinates
            }
        });
    }

    map.updateDeckLayer(LAYERID, { data: features, getLineColor: [255, 204, 0]});
}


/**
 * 长度测量
 */
export default class LengthMeasurer  {
    constructor(map) {
        this._isActivated = false;
        this._map = map;

        this._points = [];
    }

    get isActivated(){
        return this._isActivated;
    }

    activate(){
        if(this._isActivated) return;
        this._isActivated = true;
        map = this._map;
        instance = this;
        points = this._points;
        this._map.on("click", onMapClick);
        this._map.on("mousemove", onMouseMove);
        this._map.on("contextmenu",onContextmenu);
        // this._map.on("dblclick", onMapDblClick);

        this._map.addDeckGeoJsonLayer({
            id: LAYERID,
            pickable: false,
            stroked: true,
            filled: true,
            extruded: false,
            pointType: 'text',
            lineWidthUnits: "pixels",
            pointRadiusUnits: "pixels",
            getTextAlignmentBaseline: "bottom",
            getTextPixelOffset:[0, -8],
            lineWidthScale: 1,
            lineWidthMinPixels: 2,
            getFillColor: [255, 255, 255, 50],
            getLineColor: [0, 204, 255],
            getPointRadius: 2,
            getLineWidth: 2,
            getElevation: 30,
            getText: p => p.properties.text,
            textBackground: true,
            getTextSize: 18,
            getTextBackgroundColor: [255, 255, 255, 180],
            textFontFamily: "'Courier New', Courier, monospace",
            textBackgroundPadding: [5, 3]
        });

        let container = this._map.getCanvas().parentElement;
        // container.style.cursor = "crosshair";//"default";
        container.classList.add("crosshair")
    }


    deactivate(){
        if(!this._isActivated) return;
        this._isActivated = false;
        this._map.off("click", onMapClick);
        this._map.off("mousemove", onMouseMove);
        this._map.off("contextmenu",onContextmenu);
        // this._map.off("dblclick", onMapDblClick);
        map = null;
        instance = null;
        points = null;
        this._points.length = 0;

        let container = this._map.getCanvas().parentElement;
        // container.style.cursor = null;
        container.classList.remove("crosshair")
    }

    /**
     * 清除图层
     */
    clean(){
        this._map.removeDeckLayer(LAYERID);
        this.deactivate();
    }
}
