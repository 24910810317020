<template>
  <el-tooltip
      class="box-item"
      effect="dark"
      content="标注开关"
      placement="left"
  >
  <el-switch class="map-title-switch"
    v-model="visible"
    inline-prompt
    active-text="开"
    inactive-text="关"
    @change="onchange"
    v-show="showable"
    :disabled="!module"
  />
  </el-tooltip>
</template>

<script>
// import { mapState } from 'vuex'
export default {
  name: "TitleSwitch",
  computed: {
    module () {
      return this.$store.state.module
    },
    showable(){
      if(!this.module) return false;
      if(this.module.changeTitleVisible) return true;
      return false;
    }
  },
  watch:{
    module(value){
      this.visible = false;
    }
  },
  data(){
    return {
      visible: false,
      disabled: true
    }
  },
  methods:{
    onchange(){
      if(this.module){
        this.module.changeTitleVisible(this.visible)
      }
    }
  }
}
</script>

<style scoped>
.map-title-switch{
  position: absolute;
  z-index:2;
  top:5px;
  right:50px;
}
</style>
