import DrawPolygon from './draws/polygon';
import DrawRectangle from './draws/rectangle';

export default class DrawManager {
    constructor(map) {
        this.map = map;
        this._actived = false;

        this._polygon = new DrawPolygon(map);
        this._rectangle = new DrawRectangle(map);
    }

    /**
     * 激活绘图控件
     * 1:多边形绘制, 2: 拉框绘制矩形
     */
    activate(type){
        if(this._actived != type){
            if(this._actived === 1) this._polygon.clean();
            else if(this._actived === 2) this._rectangle.clean();
        }

        if(type === 1 && !this._polygon.isActivated){
            this._polygon.activate();
        }
        else if(type === 2 && !this._rectangle.isActivated){
            this._rectangle.activate();
        }

        this._actived = type;
    }

    /**
     * 反激活
     */
    deactivate(){
        if(this._actived){
            this._actived = false;

            this._polygon.deactivate();
            this._rectangle.deactivate();
        }
    }

    clean(){
        this._polygon.clean();
        this._rectangle.clean();
    }

}
