<template>
  <div id="map">
  </div>

  <Toolbar />
  <DataPad />
  <LoadFiles />
  <BasicLayerSelector />
  <TitleSwitch />
  <SliderBar />
  <LoadShpDbf />
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {initMap} from '@/libs/map/MapHelper'
import Toolbar from '@/components/map/Toolbar'
import BasicLayerSelector from '@/components/map/BasicLayerSelector'
import DataPad from '@/components/map/DataPad'
import LoadFiles from '@/components/map/LoadFiles'
import LoadShpDbf from '@/components/map/LoadShpDbf'
import TitleSwitch from '@/components/map/TitleSwitch'
import SliderBar from '@/components/map/SliderBar'

// import * as MapDraw from '@/scripts/MapDraw';
// import * as Buildings from '@/scripts/Buildings';
// import { test } from './test'

onMounted(() => {
  // var map = initMap(MapDraw.initialize);
  var map = initMap(() => {
    // map.addDeckSolidPolygonLayer({
    //   id:'adc',
    //   data:[
    //     {polygon: [[113, 30], [114, 30], [114, 31], [113, 31], [113, 30]]},
    //     {polygon: [[111, 30], [112, 30], [112, 31], [111, 30]]}
    //   ],
    //   getPolygon: d => d.polygon,
    //   getFillColor: [255, 0, 0],
    //   extruded: false
    // })
    // test()
  });
})


</script>

<style scoped>
#map{
  position:absolute;
  z-index:0;
  width:auto;
  height:auto;
  left:0px;
  right:0px;
  bottom:0px;
  top:0px;
}
</style>
