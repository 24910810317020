import { createStore } from 'vuex'

export default createStore({
  state: {
    module: null
  },
  mutations: {
    module(state, value){
      state.module = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
