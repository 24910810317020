import * as turf from '@turf/turf';
import BufferExtension from '../libs/map/plus/extensions/BufferExtension';
import RadarExtension from '../libs/map/plus/extensions/RadarExtension';

import CompositePolygonLayer from '../libs/map/plus/layers/CompositePolygonLayer'
import {LightingEffect, AmbientLight, _SunLight as SunLight} from '@deck.gl/core';


let _map = null;

const POLYGONLAYERID = "draw_buildings_hl_layer";
const TEXTLAYERID = "draw_buildings_text_layer";
const RANGELAYER = "draw_range_layer";
const ICONLAYER = "draw_icon_layer";

const brush = new BufferExtension();

let radius = 500;

function install(map, callback){
    const start = new Date().getTime();
    _map = map;

    let alyr = new CompositePolygonLayer({
        id:POLYGONLAYERID,
        data: BasicUrl + "/api/sep/desc/describe",
        pickable: true,
        autoHighlight: true,
        // wireframe: true,
        baseURL: BasicUrl,
        getFillColor: [183,234,255],
        getElevation: 50,
        extruded: true,
        highlightColor:[0,255,204],
        brushingEnabled: true,
        brushingRadius: radius,
        brushCenter:[0, 0],
        extensions:[brush],
        // extensions:[new RadarExtension()],
        onClick:(info, event)=>{
            if(info.object){
                _updateBrushCenter(info.object);
            }
        },
        onSubLayerInitialized: function({total, bbox, speed}){
            const end = new Date().getTime();
            callback({
                total: total,
                duration: end - start,
                speed:speed
            });
            const {minX, minY, maxX, maxY} = bbox;
            map.fitBounds([[minX, maxY], [maxX, minY]]);
        }
    });

    map.addDeckLayer(alyr);

    map.addDeckScatterplotLayer({
        id: RANGELAYER,
        opacity: 0.6,
        stroked: false,
        filled: true,
        radiusUnits: 'meters',
        radiusMinPixels: 1,
        // radiusMaxPixels: 100,
        lineWidthMinPixels: 1,
        getPosition: d => d,
        getRadius: radius,
        getFillColor: d => [255,255,0],
        getLineColor: d => [0, 0, 0]
    });

    map.addDeckIconLayer({
        id: ICONLAYER,
        pickable: true,
        iconAtlas: './icons/icon-atlas.png',
        iconMapping: {
            marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
        },
        getIcon: d => 'marker',
        sizeScale: 1,
        getPixelOffset: d=> [0, -16],
        getPosition: d => d,
        getSize: d => 32,
        getColor: d => [255,0,0]
    });

    // const ambientLight = new AmbientLight({
    //     color: [255, 255, 255],
    //     intensity: 1.0
    // });
    //
    // const dirLight = new SunLight({
    //     timestamp: Date.UTC(2019, 7, 1, 22),
    //     color: [255, 255, 255],
    //     intensity: 1.0,
    //     _shadow: true
    // });
    //
    // const lightingEffect = new LightingEffect({ambientLight, dirLight});
    // lightingEffect.shadowColor = [0, 0, 0, 0.5];

    map.deck.setProps({
        getTooltip:({object}) => {
            return (object && object.title);
        }
    })
}


function _updateBrushCenter({positions}){
    const length = positions.length;
    const extents = [999, 999, -999, -999];
    for(let i = 0; i < length; i += 3){
        const x = positions[i];
        const y = positions[i + 1];
        if(x < extents[0]) extents[0] = x;
        if(y < extents[1]) extents[1] = y;
        if(x > extents[2]) extents[2] = x;
        if(y > extents[3]) extents[3] = y;
    }

    const center = [(extents[0] + extents[2]) / 2, (extents[1] + extents[3]) / 2];

    _map.updateDeckLayersInBatch([
        {id: POLYGONLAYERID, brushCenter: center },
        {id: RANGELAYER, data: [center] },
        {id: ICONLAYER, data: [[...center, 51]]}
    ])
}

function setRadius(value){
    radius = value;

    _map.updateDeckLayersInBatch([
        {id: POLYGONLAYERID, brushingRadius: value },
        {id: RANGELAYER, getRadius: value }
    ]);
}


function changeTitleVisible(visible){
    _map.updateDeckLayersInBatch([
        {id: TEXTLAYERID + 1, visible: visible },
        {id: TEXTLAYERID + 2, visible: visible },
        {id: TEXTLAYERID + 3, visible: visible }
    ])
}


function uninstall(){
    _map.removeDeckLayers([
        POLYGONLAYERID,
        RANGELAYER,
        ICONLAYER
    ]);

    _map.deck.setProps({
        getTooltip: undefined
    })

    _map = null;
}

export { install,uninstall,setRadius };
