<template>
  <el-tooltip
      class="box-item"
      effect="dark"
      content="更改半径"
      placement="left"
  >
    <el-slider v-show="visible" class="map-slider-bar" v-model="radius" :min="100" :max="3000" placement="bottom" />
  </el-tooltip>
</template>

<script>
export default {
  name: "SliderBar",
  computed: {
    module () {
      return this.$store.state.module
    }
  },
  watch:{
    module(value){
      if(value && value.setRadius){
        this.visible = true;
      }
      else {
        this.visible = false;
      }
    },
    radius(value){
      this.module.setRadius(value)
    }
  },
  data(){
    return {
      radius: 500,
      visible: false
    }
  }
}
</script>

<style scoped>
.map-slider-bar{
  width: 220px;
  position: absolute;
  z-index:2;
  top:5px;
  right:60px;
}
</style>
